import React, { Component } from "react";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const classes = makeStyles(styles);

class AddSuperCategory extends Component {
  state = {
    name: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    super_category: "",
    data: [],
    image: "",

    // image preview
    imageFile: "",
    photoUrl: "",
  };

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  // handle image Preview
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();
    fd.append("title", this.state.name);
    fd.append("image", this.state.image);

    axios.post(Constants.getUrls.super_categories, fd).then((resp) => {
      // console.log(resp.data);
      if (resp.data.status === "success") {
        fd.delete("image");
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/supercategories"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Super Category Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This SuperCategory Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Super Category</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            style={{ textTranform: "capitalize" }}
                            labelText="Name"
                            id="float"
                            inputProps={{
                              required: true,
                              onChange: (e) =>
                                this.setState({ name: e.target.value }),
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                        <GridItem className="d-flex " xs={12} sm={12} md={6}>
                          <GridItem xs={12} sm={12} md={6}>
                            <label>Image</label>
                            <br />
                            <input
                              type="file"
                              onChange={(e) => {
                                this.handleFile(e);
                                this.handlePreview(e);
                              }}
                              required={true}
                              name="image"
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <label>Image Preview</label>
                            <br />
                            <img width={100} src={this.state.photoUrl} alt="" />
                          </GridItem>
                        </GridItem>
                      </GridContainer>

                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Category"}
                          </Button>
                          <Link to="/admin/supercategories">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddSuperCategory;
