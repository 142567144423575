import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import MultiSelect from "react-multi-select-component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class AddBulkOrder extends Component {
  state = {
    name: "",
    commission: "",
    categories: [],
    productsFor: [],
    productFor: "",
    category: "",
    productIds: [],
    products: [],
    filteredProductsFor: [],
    filteredSubCategories: [],
    subCategories: [],
    deliveryModes: [],
    deliveryMode: "",
    subCategory: "",
    overview: "",
    note: "",
    specification: "",
    taxType: "",
    taxTypes: [],
    price: "",
    offerType: "",
    value: "",
    quantity: "",
    vendors: [],
    image: [],
    video: [],
    customizeImage: [],
    description: "",
    customizeProduct: "no",
    vendor: "",
    customize: false,
    videoAdded: false,
    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    // axios
    //   .get(Constants.getUrls.printTypes)
    //   .then(resp => {
    //     // console.log(resp.data);
    //     resp.data.data.forEach(v => {
    //       this.state.products.push({
    //         label: v.name,
    //         value: v._id
    //       });
    //     });
    //     this.setState({
    //       products: this.state.products
    //     });
    //   });
    axios
      .get(Constants.getUrls.categories + "?limit=20&skip=0&page=0")
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          categories: resp.data.docs,
        });
      });
    axios
      .get(Constants.getUrls.productsFor + "?limit=10&skip=0&page=0")
      .then((resp) => {
        //   console.log(resp.data);
        this.setState({
          productsFor: resp.data.docs,
        });
      });
    // axios.get(Constants.getUrls.vendors).then(resp => {
    //   // console.log(resp.data);
    //   this.setState({
    //     vendors: resp.data.data
    //   });
    // });
    // axios.get(Constants.getUrls.taxTypes).then(resp => {
    //   // console.log(resp.data);
    //   this.setState({
    //     taxTypes: resp.data.data
    //   });
    // });
    axios
      .get(Constants.getUrls.subCategories + "?limit=10&skip=0&page=0")
      .then((resp) => {
        //   console.log(resp.data);
        this.setState({
          subCategories: resp.data.docs,
        });
      });
    // axios.get(Constants.getUrls.deliveryModes).then(resp => {
    //   // console.log(resp.data);
    //   this.setState({
    //     deliveryModes: resp.data.data
    //   });
    // });
  }
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        let filteredSubCategories = this.state.subCategories.filter(
          (v) => (v.category ? v.category._id : "") === this.state.category
        );
        let filteredProductsFor = this.state.productsFor.filter(
          (v) => (v.category ? v.category._id : "") === this.state.category
        );
        console.log(filteredSubCategories);
        console.log(filteredProductsFor);
        this.setState({ filteredSubCategories, filteredProductsFor });
      }
    );
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files });
  };
  handleVideo = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], videoAdded: true });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    // if (this.state.productIds.length===0) {
    //   this.setState({warn:true});
    // } else {
    this.setState({
      loading: true,
    });
    let prodIds = [];
    console.log(this.state.productIds);
    this.state.productIds.forEach((v) => {
      prodIds.push(v.value);
    });
    let fd = new FormData();
    fd.append("product_for", this.state.productFor);
    for (let i = 0; i < this.state.image.length; i++) {
      fd.append("image", this.state.image[i]);
    }
    if (this.state.videoAdded) {
      fd.append("video", this.state.video);
    }
    fd.append("name", this.state.name);
    fd.append("catId", this.state.category);
    fd.append("subcatId", this.state.subCategory);
    // fd.append("vendorId", this.state.vendor);
    // fd.append("vendor_commission", this.state.commission);
    // fd.append("delivery_modeId", this.state.deliveryMode);
    if (this.state.specification !== "") {
      fd.append("specification", this.state.specification);
    }
    if (this.state.note !== "") {
      fd.append("note", this.state.note);
    }
    if (this.state.overview !== "") {
      fd.append("product_overview", this.state.overview);
    }
    // fd.append("tax_type", this.state.taxType);
    if (this.state.price !== "") {
      fd.append("price", this.state.price);
    }
    // if (this.state.offerType !== "") {
    // fd.append("offer_type", this.state.offerType);
    // }
    // if (this.state.value !== "") {
    // fd.append("offer_value", this.state.value);
    //   }
    // fd.append("printIds", JSON.stringify(prodIds));
    if (this.state.quantity !== "") {
      fd.append("product_quantity", this.state.quantity);
    }
    //   fd.append("customize_product", this.state.customizeProduct);
    //   if (this.state.customizeProduct === "yes") {
    //   fd.append("customize_product_image", this.state.customizeImage);
    //   fd.append("customize_product_description", this.state.description);
    // }
    axios.post(Constants.getUrls.bulkOrders, fd).then((resp) => {
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warning: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
        }, 1000);
      }
    });
    //   };
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/bulkorders"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Bulk Product Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Bulk Product</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="category"
                              required={true}
                              onChange={this.handleCategory}
                            >
                              <option value="">Select an Option</option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Sub Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="subCategory"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select Category First!</option>
                              {this.state.filteredSubCategories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Product For</label>
                            <br />
                            <select
                              className="form-control"
                              name="productFor"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select Category First!</option>
                              {this.state.filteredProductsFor.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.category_name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Vendor</label>
                            <br />
                            <select
                              className="form-control"
                              name="vendor"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.vendors.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Vendor Commision</label>
                            <Input
                              placeholder="Vendor Commision"
                              name="commission"
                              onChange={this.handleInput}
                              required={true}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Delivery Mode</label>
                            <br />
                            <select
                              className="form-control"
                              name="deliveryMode"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.deliveryModes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.delivery_mode}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Tax Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="taxType"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.taxTypes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.tax_type}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <label htmlFor="Print Types">Print Types</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.products}
                            value={this.state.productIds}
                            onChange={data => {
                              this.setState({ productIds: data });
                            }}
                            labelledBy={"Print Types"}
                          />
                           {this.state.warn ? (
                          <div>
                            <span style={{ color: "red" }}>*required</span>
                          </div>
                        ) : null}
                        </Col> */}

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Quantity</label>
                            <Input
                              placeholder="Quantity"
                              name="quantity"
                              onChange={this.handleInput}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Price</label>
                            <Input
                              placeholder="Price"
                              name="price"
                              onChange={this.handleInput}
                              type="float"
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="offerType"
                              onChange={this.handleInput}
                            >
                              <option value="">
                                Select Option
                                </option>
                              {["amount", "percent"].map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Value</label>
                            <Input
                              placeholder="Offer Value"
                              name="value"
                              onChange={this.handleInput}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col style={{ marginTop: "8px" }} md="6">
                          <label>Image</label>
                          <br />
                          <input
                            type="file"
                            multiple
                            onChange={this.handleFile}
                            required={true}
                            name="image"
                          />
                        </Col>
                        <Col style={{ marginTop: "8px" }} md="6">
                          <label>Video</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleVideo}
                            name="video"
                            accept="video/*"
                          />
                        </Col>
                        <Col md="12">
                          <label>Product Overview</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                overview: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Note</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                note: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Specification</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                specification: data,
                              });
                            }}
                          />
                          <br />
                        </Col>

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Customize Product</label>
                            <br />
                            <select
                              className="form-control"
                              name="customizeProduct"
                              value={this.state.customizeProduct}
                              onChange={this.handleInput}
                            >
                              {["no", "yes"].map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      {/* {this.state.customizeProduct === "yes" ? (<Row>
                        <Col md="12">
                          <label>Image</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleFile}
                            name="customizeImage"
                          />
                        </Col>
                        <Col md="12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                description: data
                              });
                            }}
                          />
                          <br />
                        </Col>

                      </Row>) : null} */}
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Adding..."
                              : "Add Bulk Product"}
                          </Button>
                          <Link to="/admin/bulkorders">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddBulkOrder;
