// import React, { Component } from "react";
// import axios from "axios";
// import { SketchPicker } from "react-color";
// import { Redirect, Link } from "react-router-dom";
// import {
//   Button,
//   CardBody,
//   CardTitle,
//   Col,
//   Form,
//   FormGroup,
//   Input,
//   Row,
//   CardHeader,
//   UncontrolledAlert,
//   Container,
// } from "reactstrap";
// import Constants from "variables/Constants";
// export default class AddVariants extends Component {
//   state = {
//     data: [],
//     allColor:[],
//     size: "",
//     color: "",
//     pack_of: "",
//     image: "",
//     // design_image: "",
//     price: "",
//     imgAdd: false,

//     p_id: this.props.match.params.id,
//     // p_id: this.props.match.params.id,

//     redirect: false,
//   };
//   componentDidMount() {
//     this.getAllSize();
//     this.getAllColor();
//     // console.log(this.props);
//   }
//   getAllSize = () => {
//     axios
//       .get(
//         // Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1"
//         Constants.getUrls.getSize
//       )
//       .then((resp) => {
//         console.log(resp, "yes");
//         this.setState({
//           data: resp.data._sizes.docs,
//         });
//       });
//   };
//   getAllColor = () => {
//     axios
//       .get(
//         // Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1"
//         Constants.getUrls.getColor
//       )
//       .then((resp) => {
//         console.log(resp, "rr");
//         this.setState({
//           allColor: resp.data._colors.docs,
//         });
//       });
//   };



//   // /select ====================
//   // handleSize = (e) => {
//   //   this.setState(
//   //     {
//   //       [e.target.name]: e.target.value,
//   //     },
//   //     () => {
//   //       axios
//   //         .get(
//   //           Constants.getUrls.getSize +
//   //             "?limit=50&page=1&status=active" 
//   //         )
//   //         .then((resp) => {
//   //           console.log(resp);
//   //           // this.setState({
//   //           //   data: resp.data._sizes.docs,
//   //           // });
//   //         });
//   //     }
//   //   );
//   // };



//   handleInput = (e) => {
//     this.setState({
//       [e.target.name]: e.target.value,
//     });
//   };
//   handleFile = (e) => {
//     this.setState({ [e.target.name]: e.target.files, imgAdd: true });
//   };
//   handleSubmit = (e) => {
//     e.preventDefault();

//     let fd = new FormData();
//     fd.append("size", this.state.size);
//     fd.append("color", this.state.color);
//     fd.append("pack_of", this.state.pack_of);
//     if (this.state.image.length > 0) {
//       for (let i = 0; i < this.state.image.length; i++) {
//         fd.append("image", this.state.image[i]);
//       }
//     }
//     // for (let i = 0; i < this.state.design_image.length; i++) {
//     //   fd.append("design_image", this.state.design_image[i]);
//     // }
//     fd.append("price", this.state.price);
//     fd.append("p_id", this.state.p_id);
//     this.setState({
//       loading: true,
//     });
//     axios.post(Constants.postUrls.postVariants, fd).then((res) => {
//       console.log(res, "rommm");
//       if (res.data.status === "success") {
//         setTimeout(() => {
//           this.setState({
//             redirect: true,
//           });
//         }, 1000);
//         this.setState({
//           notification: true,
//           loading: false,
//         });
//       } else {
//         this.setState({
//           warning: true,
//           loading: false,
//         });
//         setTimeout(() => {
//           this.setState({
//             warning: false,
//           });
//         }, 1000);
//       }
//     });
//     // };
//   };
//   handleChangeComplete = (color) => {
//     this.setState({ color: color.hex });
//   };
//   render() {
//     if (this.state.redirect) {
//       return <Redirect to={"/admin/variants/" + this.state.p_id} />;
//     } else {
//       return (
//         <div className="content">
//           <CardHeader>
//             <CardTitle tag="h4"> Add Variants</CardTitle>

//             {this.state.notification ? (
//               <div
//                 style={{
//                   width: "250px",
//                   position: "relative",
//                   left: "40%",
//                   top: "7px",
//                 }}
//               >
//                 <UncontrolledAlert color="success" fade={false}>
//                   <span className="alert-inner--icon">
//                     <i className="ni ni-like-2" />
//                   </span>{" "}
//                   <span className="alert-inner--text">
//                     <strong>Variant Added!</strong>
//                   </span>
//                 </UncontrolledAlert>
//               </div>
//             ) : null}
//             {this.state.warning ? (
//               <div
//                 style={{
//                   width: "250px",
//                   position: "relative",
//                   left: "40%",
//                   top: "7px",
//                 }}
//               >
//                 <UncontrolledAlert color="danger" fade={false}>
//                   <span className="alert-inner--icon">
//                     <i className="ni ni-dislike-2" />
//                   </span>{" "}
//                   <span className="alert-inner--text">
//                     <strong>Some Error Occured!</strong>
//                   </span>
//                 </UncontrolledAlert>
//               </div>
//             ) : null}
//           </CardHeader>
//           <CardBody>
//             <Container>
//               <Form onSubmit={this.handleSubmit}>
//                 <Row>
//                   <Col className="pr-1" md="6">
//                     <FormGroup>
//                       <label>Size </label>
//                       <br />
//                       {/* <Input
//                         placeholder="Size "
//                         name="size"
//                         width="50%"
//                         onChange={this.handleInput}
//                         // required={true}
//                         type="float"
//                       /> */}
//                       {/* <select
//                               className="form-control"
//                               name="size"
//                               required={true}
//                               onChange={this.handleSize}
//                             >
//                               <option value="">Select an Option</option>
//                               {this.state.data.map((v, i) => (
//                                 <option key={i} value={v._id}>
//                                   {v.size}
//                                 </option>
//                               ))}
//                             </select> */}
//                       <select
//                         className="form-control"
//                         name="size"
//                         // required={true}
//                         onChange={this.handleInput}
//                       >
//                         <option value="">
//                           Select an Option</option>
//                         {this.state.data.map((v, i) => (
//                           <option key={i} value={v._id}>
//                             {v.size}
//                           </option>
//                         ))}
//                       </select>
//                     </FormGroup>
//                   </Col>

//                   <Col className="pr-1" md="6">
//                     <FormGroup>
//                       <label>Pack Of</label>
//                       <br />
//                       <Input
//                         placeholder="Pack Of"
//                         name="pack_of"
//                         onChange={this.handleInput}
//                       // required={true}
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col className="pr-1" md="6">
//                     <FormGroup>
//                       <label>Price</label>
//                       <br />
//                       <Input
//                         placeholder="Price"
//                         name="price"
//                         onChange={this.handleInput}
//                         type={"number"}
//                       // required={true}
//                       />
//                     </FormGroup>
//                   </Col>
//                   <Col className="pr-1" md="6">
//                     <FormGroup>
//                       <label>Color</label>
//                       <br />
//                       <select
//                         className="form-control"
//                         name="color"
//                         // required={true}
//                         onChange={this.handleInput}
//                       >
//                         <option value="">Select an Option</option>
//                         {this.state.allColor.map((v, i) => (
//                           <option key={i} value={v._id}>
//                             {v.color}
//                           </option>
//                         ))}
//                       </select>
//                       {/* <Input
//                         type="text"
//                         placeholder="Color"
//                         onChange={this.handleInput}
//                         required={true}
//                         name="color"
//                       /> */}
//                       {/* <SketchPicker
//                         // required={true}
//                         color={this.state.color}
//                         onChangeComplete={this.handleChangeComplete}
//                       /> */}

//                       {/* <SketchPicker placeholder="Color" name="color"onChange={this.handleInput} required={true} /> */}
//                     </FormGroup>
//                   </Col>
//                   {/* <Col style={{ marginTop: "8px" }} md="6">
//                     <label> Design Image</label>
//                     <br />
//                     <input
//                       type="file"
//                       onChange={this.handleFile}
//                       // required={true}
//                       name="design_image"
//                     />
//                   </Col> */}
//                   <Col style={{ marginTop: "8px" }} md="6">
//                     <label>Image</label>
//                     <br />
//                     <input
//                       type="file"
//                       multiple
//                       onChange={this.handleFile}
//                       // required={true}
//                       name="image"
//                     />
//                   </Col>

//                   {/* <Col className="pr-1" md="3">
//                 <FormGroup>
//                   <label>Price</label>
//                   <br />
//                   <Input
//                     type="number"
//                     placeholder="Price"
//                     name="price"
//                     onChange={this.handleInput}
//                     required={true}
//                   />
//                 </FormGroup>
//               </Col> */}
//                 </Row>

//                 <Row>
//                   <Col className="pr-1" md="6">
//                     <Button className="btn-round" color="success" type="submit">
//                       {this.state.loading ? "Adding..." : "  Add Variant"}
//                     </Button>
//                     <Link to="/admin/index">
//                       <Button className="btn-round" color="danger">
//                         Cancel
//                       </Button>
//                     </Link>
//                   </Col>
//                 </Row>
//               </Form>
//             </Container>
//           </CardBody>
//         </div>
//       );
//     }
//   }
// }
import React, { Component } from 'react'

export default class AddVariants extends Component {
  render() {
    return (
      <div>AddVariants</div>
    )
  }
}
