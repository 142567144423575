import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Table,
  Row,
  Col,
  Button,
  UncontrolledAlert,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import { IconButton } from "@material-ui/core";
import "../css/ToolTipStyle.css";
// import DeleteIcon from '@mui/icons-material/Delete';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonColor: "success",
      data: [],
      productIds: [],
      products: [],
      all_filter_product: [],
      selectedID: "",

      warn: false,
      redirect: false,
      warning: false,
      notification: false,
      loading: false,
      super_categories: [],
      super_category: "",
      __superCategories: [],
      __categories: [],
      __subCategories: [],
      categories: [],
      category: "",
      sub_categories: [],
      sub_category: "",
      superCategoryValue: "",
      categoryValue: "",
      subCategoryValue: "",

      notifyStatus: false,
      notifyDelete: false,
      visible: false,

      searching: false,
      searched: false,
      delLoading: false,

      trendId: 0,
      notifyTrending: false,
      visibleTrending: false,
      trending: false,
      trendingStatus: "",

      bestSellerId: 0,
      notifyBestSellers: false,
      visibleBestSellers: false,
      bestsellers: false,
      bestSellersStatus: "",
      rowId: 0,
      status: "",

      active: 1,
      perPage: "10",
      currentPage: 1,
      prevPage: 0,
      nextPage: 0,
      lastPage: 0,
      lastPageView: false,
      firstPageView: false,
      total: 0,
      from: 0,
      to: 0,
      fromInc: 1,
      toInc: 5,
      btnIdArray: [],
      filteredBtnIdArray: [],
      // query: "",
    };
  }

  componentDidMount() {
    this.getCategories();
    this.fetchSuperCategories();
    this.fetchSubCategories();
    // this.fetchCategories();
    // this.fetchSubCategories();
    // axios
    //   .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1" + "&is_delete=no")
    //   .then((resp) => {
    //     console.log(resp, "rr");
    //     this.setState({
    //       super_categories: resp.data.superCat.docs,
    //     });
    //   });
  }
  // to get Super Category Id and name
  fetchSuperCategories = () => {
    axios
      .get(`${Constants.getUrls.super_categories}?is_delete=no&status=ACTIVE`)
      .then((response) => {
        // console.log(response.data.superCat.docs);
        this.setState({
          __superCategories: response.data.superCat.docs,
        });
      });
  };

  // to get Category Id and name
  fetchCategories = () => {
    axios
      .get(
        Constants.getUrls.categories +
          "?super_category=" +
          this.state.superCategoryValue +
          "&status=ACTIVE"
      )
      .then((response) => {
        // console.log(response.data.docs);
        this.setState({
          __categories: response.data.docs,
        });
      });
  };
  // to get sub categories id and name
  fetchSubCategories = () => {
    axios
      .get(
        Constants.getUrls.subCategories +
          "?category=" +
          this.state.categoryValue +
          "&status=ACTIVE"
      )
      .then((response) => {
        console.log(response.data.docs);
        this.setState({
          __subCategories: response.data.docs,
        });
      });
  };
  getCategories = () => {
    axios
      .get(
        Constants.getUrls.products +
          "?limit=" +
          this.state.perPage +
          "&skip=0&page=" +
          "&sort=true" +
          this.state.active +
          "&is_delete=no" +
          "&super_category=" +
          this.state.superCategoryValue +
          "&category=" +
          this.state.categoryValue +
          "&sub_category=" +
          this.state.subCategoryValue
      )
      .then((resp) => {
        console.log(resp, "all-products");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.totalPages; i++) {
          btnIdArray.push(i);
        }
        this.setState(
          {
            fromInc: 1,
            active: 1,
            toInc: 5,
            btnIdArray,
            data: resp.data.docs,
            total: resp.data.totalDocs,
            from: 1,
            to: resp.data.docs.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };

  handlePage = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        fromInc: 1,
        active: 1,
        searched: false,
        name: "",
        toInc: 5,
      },
      () => {
        axios
          .get(
            Constants.getUrls.products +
              "?limit=" +
              this.state.perPage +
              "&is_delete=no&status=ACTIVE" +
              "&skip=0&page=" +
              this.state.active
          )
          .then((resp) => {
            // console.log(resp.data);
            let btnIdArray = [];
            for (let i = 1; i <= resp.data.totalPages; i++) {
              btnIdArray.push(i);
            }
            this.setState(
              {
                btnIdArray,
                data: resp.data.docs,
                total: resp.data.totalDocs,
                from: 1,
                to: resp.data.docs.length,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({
                  filteredBtnIdArray,
                });
              }
            );
          });
      }
    );
  };

  currentPage = (v) => {
    if (this.state.searched) {
      let commonUrl =
        Constants.postUrls.searchProduct +
        "?limit=" +
        this.state.perPage +
        "&is_delete=no" +
        "&sort=true" +
        "&skip=0&page=";

      axios.post(commonUrl + v, { name: this.state.name }).then((resp) => {
        console.log(resp.data, "product by search");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.pageCount; i++) {
          resp.data && btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            data: resp.data.data,
            total: resp.data.itemCount,
            // from: 1,
            // fromInc: 1,
            active: v,
            // toInc: 5,
            currentPage: v,

            searched: true,
            searching: false,
            from: v * this.state.perPage - (this.state.perPage - 1),
            to:
              v * this.state.perPage > this.state.total
                ? this.state.total
                : v * this.state.perPage,
            // to: resp.data?.data?.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
    } else {
      let commonUrl =
        Constants.getUrls.products +
        "?limit=" +
        this.state.perPage +
        "&is_delete=no" +
        "&skip=0&page=";
      axios.get(commonUrl + v).then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            data: resp.data.docs,
            total: resp.data.totalDocs,
            from: v * this.state.perPage - (this.state.perPage - 1),
            to:
              v * this.state.perPage > this.state.total
                ? this.state.total
                : v * this.state.perPage,
            active: v,
            currentPage: v,
          },
          () => {
            // console.log(typeof this.state.data[0].image);
          }
        );
      });
    }
  };

  prevPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc - 5,
        toInc: this.state.toInc - 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc + 4,
                toInc: this.state.toInc + 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc + 3,
                toInc: this.state.toInc + 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc + 2,
                toInc: this.state.toInc + 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc + 1,
                toInc: this.state.toInc + 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc + 5,
              toInc: this.state.toInc + 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  nextPages = () => {
    this.setState(
      {
        fromInc: this.state.fromInc + 5,
        toInc: this.state.toInc + 5,
      },
      () => {
        let filteredBtnIdArray = this.state.btnIdArray.filter(
          (v) => v >= this.state.fromInc && v <= this.state.toInc
        );
        if (filteredBtnIdArray.length >= 1) {
          if (filteredBtnIdArray.length === 1) {
            this.setState(
              {
                fromInc: this.state.fromInc - 4,
                toInc: this.state.toInc - 4,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 2) {
            this.setState(
              {
                fromInc: this.state.fromInc - 3,
                toInc: this.state.toInc - 3,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 3) {
            this.setState(
              {
                fromInc: this.state.fromInc - 2,
                toInc: this.state.toInc - 2,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else if (filteredBtnIdArray.length === 4) {
            this.setState(
              {
                fromInc: this.state.fromInc - 1,
                toInc: this.state.toInc - 1,
              },
              () => {
                let filteredBtnIdArray = this.state.btnIdArray.filter(
                  (v) => v >= this.state.fromInc && v <= this.state.toInc
                );
                this.setState({ filteredBtnIdArray });
              }
            );
          } else {
            this.setState({ filteredBtnIdArray });
          }
        } else {
          this.setState(
            {
              fromInc: this.state.fromInc - 5,
              toInc: this.state.toInc - 5,
            },
            () => {
              let filteredBtnIdArray = this.state.btnIdArray.filter(
                (v) => v >= this.state.fromInc && v <= this.state.toInc
              );
              this.setState({ filteredBtnIdArray });
            }
          );
        }
      }
    );
  };

  handleStatus = (e, id) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        const payload = {
          status: this.state.status,
        };
        axios
          .post(Constants.statusUrl + id + "?type=product", payload)
          .then(() => {
            this.setState({
              notifyStatus: true,
            });
            setTimeout(() => {
              this.setState({ notifyStatus: false }, () => {
                this.getCategories();
              });
            }, 1000);
          });
      }
    );
  };

  handleDelete = (id) => {
    this.setState({ delLoading: true });
    axios.delete(Constants.getUrls.products + "/" + id).then(() => {
      this.setState({
        notifyDelete: true,
        delLoading: false,
      });
      setTimeout(() => {
        this.setState({ notifyDelete: false, visible: false }, () => {
          this.getCategories();
        });
      }, 2000);
    });
  };

  handleTrending = (id) => {
    this.setState({ trending: true });
    axios
      .post(Constants.postUrls.setTrending + id, {
        trending_status: this.state.trendingStatus,
      })
      .then(() => {
        this.setState({
          notifyTrending: true,
          trending: false,
        });
        setTimeout(() => {
          this.setState(
            { notifyTrending: false, visibleTrending: false },
            () => {
              this.getCategories();
            }
          );
        }, 2000);
      });
  };

  handleModal = (sec, arg, status) => {
    this.setState({
      rowId: sec._id,
      trendId: sec._id,

      trendingStatus: status,
      bestSellersStatus: status,

      bestSellerId: sec._id,
      visible: arg === "bestsellers" ? false : true,
      visibleBestSellers: arg === "bestsellers" ? true : false,

      visible: arg === "trending" ? false : true,
      visibleTrending: arg === "trending" ? true : false,
    });
  };

  // best seller status=====
  handleBestseller = (product) => {
    console.log(product.bestseller_status);
    this.setState({ bestsellers: true });

    if (product.bestseller_status === "yes") {
      axios
        .post(Constants.postUrls.setBestSeller + product._id, {
          bestseller_status: "no",
        })
        .then((resp) => {
          console.log(resp);
          this.setState({
            notifyBestSellers: true,
            bestsellers: false,
          });
          setTimeout(() => {
            this.setState(
              { notifyBestSellers: false, visibleBestSellers: false },
              () => {
                this.getCategories();
              }
            );
          }, 2000);
        });
    } else {
      axios
        .post(Constants.postUrls.setBestSeller + product._id, {
          bestseller_status: "yes",
        })
        .then((resp) => {
          console.log(resp);

          this.setState({
            notifyBestSellers: true,
            bestsellers: false,
          });
          setTimeout(() => {
            this.setState(
              { notifyBestSellers: false, visibleBestSellers: false },
              () => {
                this.bestSellerSubmit(product);
                this.getCategories();
              }
            );
          }, 2000);
        });
    }
  };

  // add=======
  bestSellerSubmit = (product) => {
    console.log(product, "rom");

    let payLoad = {
      super_category: product?.super_category?._id,
      category: product?.category?._id,
      sub_category: product?.sub_category?._id,
      products: product?._id,
    };
    // console.log(payLoad, "romen");
    axios.post(Constants.getUrls.bestSellers, payLoad).then((resp) => {
      // console.log(resp, "romen");
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            notification: false,
            // buttonColor:'orange'
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warning: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
        }, 1000);
      }
    });
  };

  productSearch = () => {
    this.setState({ searching: true });
    axios
      .post(
        Constants.postUrls.searchProduct +
          "?limit=" +
          this.state.perPage +
          "&is_delete=no" +
          "&skip=0&page=1",
        { name: this.state.name }
      )
      .then((resp) => {
        console.log(resp.data, "product by search");
        let btnIdArray = [];
        for (let i = 1; i <= resp.data.pageCount; i++) {
          resp.data && btnIdArray.push(i);
        }
        this.setState(
          {
            btnIdArray,
            data: resp.data.data,
            total: resp.data.itemCount,
            from: 1,
            fromInc: 1,
            active: 1,
            toInc: 5,
            searched: true,
            searching: false,
            to: resp.data?.data?.length,
          },
          () => {
            let filteredBtnIdArray = this.state.btnIdArray.filter(
              (v) => v >= this.state.fromInc && v <= this.state.toInc
            );
            this.setState({
              filteredBtnIdArray,
            });
          }
        );
      });
  };
  handleDownload = (
    _id,
    super_category,
    category,
    name,
    short_description,
    product_return,
    product_exchange,
    delivery_amount,
    mrp_price,
    selling_price,
    status,
    createdAt
  ) => {
    // console.log(name,image, mobile_no)
    let payload = {
      // "columns":[name,image,mobile_no]
      columns: [
        "_id",
        "super_category",
        "category",
        "sub_category",
        "name",
        "short_description",
        "product_return",
        "product_exchange",
        "delivery_amount",
        "mrp_price",
        "selling_price",
        "status",
        "createdAt",
      ],
    };
    axios
      .post(Constants.postUrls.downloadCsvProduct, payload, {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDI0NDFlYjFlNDZiOTNkYzFhNDcxODQiLCJpYXQiOjE2ODEyNzkzNjB9.4J-fKMcFuuYKIOVLcqKiKzHNxjG8EFTmtDLdhdv-pIU",
        },
      })
      .then((res) => {
        console.log("@@@", res.data.url);
        if (res.data) {
          if (res.data.url) {
            let baseurl = Constants.imgUrl + res.data.url;
            window.open(baseurl, "_blank");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // let data = JSON.stringify({
    //   "columns": [
    //     name,
    //     email,
    //     mobile_no,
    //     dob
    //   ]
    // });

    // console.log(data)

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'https://core.gifty60.com/user/exportExcel',
    //   headers: {
    //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDI0NDFlYjFlNDZiOTNkYzFhNDcxODQiLCJpYXQiOjE2ODEyNzkzNjB9.4J-fKMcFuuYKIOVLcqKiKzHNxjG8EFTmtDLdhdv-pIU',
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };

    // axios.request(config)
    // .then((response) => {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  // handleCatgory = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //       category: ""
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.categories +
  //           "?limit=50&page=1&status=active" +
  //           `&super_category=` +
  //           this.state.super_category + "&is_delete=no"
  //         )
  //         .then((resp) => {
  //           // console.log(resp);
  //           this.setState({
  //             categories: resp.data.docs,
  //           });
  //         });
  //     }
  //   );
  // };

  // handleSubCategory = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.subCategories +
  //           "?limit=50&page=1&status=active" +
  //           "&category=" +
  //           this.state.category +
  //           `&super_category=` +
  //           this.state.super_category + "&is_delete=no"
  //         )
  //         .then((resp) => {
  //           // console.log(resp);
  //           this.setState({
  //             sub_categories: resp.data.docs,
  //           });
  //         });
  //     }
  //   );
  // };

  render() {
    const { buttonColor } = this.state;
    const { currentPage } = this.state;
    const totalPages = 10; // Total number of pages
    return (
      <>
        <div className="content">
          {this.state.notifyStatus ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Status Changed!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}
          <Row>
            <Col md="12">
              <Card style={{ position: "relative" }}>
                <FormGroup
                  style={{
                    position: "absolute",
                    top: "26px",
                    right: "2%",
                    width: "85px",
                  }}
                >
                  <label>Per Page</label>
                  <br />
                  <select
                    style={{ height: "2.8rem" }}
                    className="form-control"
                    name="perPage"
                    value={this.state.perPage}
                    onChange={this.handlePage}
                  >
                    {[10, 25, 50].map((category, i) => (
                      <option key={i} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <Col
                  md="3"
                  style={{ position: "absolute", top: "60px", right: "18.5%" }}
                >
                  <FormGroup style={{ position: "absolute", right: "10%" }}>
                    {/* <Input
                      placeholder="Name"
                      name="name"
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value })
                      }}
                      type="text"
                    /> */}
                    <Input
                      type="text"
                      name="name"
                      value={this.state.name}
                      placeholder="Name"
                      onChange={(e) => {
                        this.setState(
                          {
                            name: e.target.value,
                          },
                          () => {
                            this.getCategories();
                          }
                        );
                      }}
                    />
                  </FormGroup>
                  <Button
                    style={{ position: "absolute", right: "-80px", top: "0px" }}
                    className="btn-round"
                    color="success"
                    type="submit"
                    onClick={this.productSearch}
                  >
                    {this.state.searching ? "Working" : "Search"}
                  </Button>
                </Col>
                <CardHeader>
                  <CardTitle tag="h4">Products Table</CardTitle>
                  <Link to="/admin/addproduct">
                    <Button className="" color="success">
                      Add Product
                    </Button>
                  </Link>
                  <Button
                    style={{ marginLeft: "20px", marginTop: "0px" }}
                    className="btn-round"
                    color="primary"
                    onClick={() => {
                      this.handleDownload();
                    }}
                  >
                    Export To CSV
                  </Button>

                  <div className="d-flex mt-2">
                    {/* superCategories */}
                    <FormGroup
                      style={{
                        width: "300px",
                        paddingRight: "10px",
                      }}
                    >
                      <label>Super Category</label>
                      <br />
                      <select
                        style={{ height: "2.8rem" }}
                        className="form-control"
                        name="perPage"
                        value={this.state.superCategoryValue}
                        onChange={(e) => {
                          this.setState(
                            { superCategoryValue: e.target.value },
                            () => {
                              this.getCategories();
                              this.fetchCategories();
                            }
                          );
                        }}
                      >
                        <option disabled selected="true" value={""}>
                          Select an Option
                        </option>
                        {this.state.__superCategories.map(
                          (superCategory, i) => (
                            <>
                              <option key={i} value={superCategory._id}>
                                {superCategory.title}
                              </option>
                            </>
                          )
                        )}
                      </select>
                    </FormGroup>
                    {/* catgories */}
                    <FormGroup
                      style={{
                        width: "300px",
                        paddingRight: "10px",
                      }}
                    >
                      <label> Category</label>
                      <br />
                      <select
                        style={{ height: "2.8rem" }}
                        className="form-control"
                        name="perPage"
                        value={this.state.categoryValue}
                        onChange={(e) => {
                          this.setState(
                            { categoryValue: e.target.value },
                            () => {
                              this.getCategories();
                              this.fetchSubCategories();
                            }
                          );
                        }}
                      >
                        <option disabled selected="true" value="">
                          {this.state.superCategoryValue.length > 0
                            ? "Now Select a Category"
                            : "Select Super Category First!"}
                        </option>
                        {this.state.__categories.map((category, i) => (
                          <>
                            {/* <option value="">
                              Select Super Category First!
                            </option> */}
                            <option key={i} value={category._id}>
                              {category.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </FormGroup>
                    {/* subcategories */}
                    <FormGroup
                      style={{
                        width: "300px",
                        paddingRight: "10px",
                      }}
                    >
                      <label>Sub Category</label>
                      <br />
                      <select
                        style={{ height: "2.8rem" }}
                        className="form-control"
                        name="perPage"
                        value={this.state.subCategoryValue}
                        onChange={(e) => {
                          this.setState(
                            { subCategoryValue: e.target.value },
                            () => {
                              this.getCategories();
                            }
                          );
                        }}
                      >
                        <option disabled selected="true" value="">
                          Select Category First!
                        </option>
                        {this.state.__subCategories.map((subCategory, i) => (
                          <>
                            <option key={i} value={subCategory._id}>
                              {subCategory.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </FormGroup>
                  </div>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Sr.No</th>
                        <th>
                          <span style={{ whiteSpace: "wrap" }}>Name</span>
                        </th>
                        <th>Image</th>
                        <th>Category</th>

                        <th>Status</th>
                        <th>Trending</th>
                        <th>Actions</th>
                        <th></th>
                        <th></th>

                        {/* <th>Trending</th> */}
                        {/* <th>CSV</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data?.map((v, i) => (
                        <tr key={i}>
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {i + 1}
                            </span>
                          </td>
                          <td
                            style={{
                              position: "relative",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                top: "30px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {/* {v?.name} */}
                              {v.name?.length > 40
                                ? v.name.substring(0, 40) + "..."
                                : v.name}
                            </span>
                          </td>
                          <td>
                            {/* {v.image ? (
                              <img
                                src={
                                  typeof v.image == "string"
                                    ? Constants.imgUrl + v.image
                                    : Constants.imgUrl + v.image[0]
                                }
                                width="80px"
                                alt="img"
                              />
                            ) : (
                              "NA"
                            )} */}

                            {v.lowest_variant?.image ? (
                              <img
                                src={
                                  typeof v.lowest_variant?.image == "string"
                                    ? Constants.imgUrl +
                                      v.lowest_variant?.image[1]
                                    : Constants.imgUrl +
                                      v.lowest_variant?.image[0]
                                }
                                width="80px"
                                alt="img"
                              />
                            ) : (
                              "NA"
                            )}
                          </td>
                          <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.category ? v.category.name : "NA"}
                            </span>
                          </td>
                          {/* <td style={{ position: "relative" }}>
                            <span style={{ position: "absolute", top: "30px" }}>
                              {v.price ? "Rs. " + v.price : "NA"}
                            </span>
                          </td> */}
                          {/* <td style={{ position: "relative" }}><span style={{ position: "absolute", top: "30px" }}>{v.sub_category ? v.sub_category.name : "NA"}</span></td>
                          <td style={{ position: "relative" }}><span style={{ position: "absolute", top: "30px" }}>{v.vendor ? v.vendor.name : "NA"}</span></td>
                          <td style={{ position: "relative" }}><span style={{ position: "absolute", top: "30px" }}>{v.vendor_commission}</span></td> */}
                          <td>
                            <select
                              className="form-control"
                              name="status"
                              style={{ width: "11yaxty0px" }}
                              value={v.status}
                              onChange={(e) => this.handleStatus(e, v._id)}
                            >
                              <option value="ACTIVE">ACTIVE</option>
                              <option value="INACTIVE">INACTIVE</option>
                            </select>
                          </td>

                          <td>
                            {v.trending_status === "no" ? (
                              <Button
                                className="btn-round"
                                color="success"
                                type="submit"
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                                onClick={() => {
                                  this.handleModal(v, "trending", "yes");
                                }}
                              >
                                {/* Trend */}
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    trending_up
                                  </span>

                                  <span className="mongo-title">Trend</span>
                                </div>
                              </Button>
                            ) : (
                              <Button
                                className="btn-round"
                                color="warning"
                                type="submit"
                                onClick={() => {
                                  this.handleModal(v, "trending", "no");
                                }}
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                              >
                                {/* Trending */}
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    trending_up
                                  </span>

                                  <span className="mongo-title">Trending</span>
                                </div>
                              </Button>
                            )}
                          </td>

                          {/* addbest seller=== */}

                          <td>
                            {v.bestseller_status === "no" ? (
                              <Button
                                className="btn-round"
                                color="success"
                                type="submit"
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                                // onClick={() => this.bestSellerSubmit(v,"bestsellers","yes")}
                                onClick={() => {
                                  this.handleBestseller(
                                    v,
                                    "bestsellers",
                                    "yes"
                                  );
                                }}
                              >
                                {/* {this.state.loading
                                  ? "Adding..."
                                  : "Add Best Seller"} */}
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    emoji_events
                                  </span>

                                  <span className="mongo-title">
                                    Add Best Seller
                                  </span>
                                </div>
                              </Button>
                            ) : (
                              <Button
                                className="btn-round"
                                color="warning"
                                type="submit"
                                // onClick={() => this.bestSellerSubmit(v)}
                                onClick={() => {
                                  this.handleBestseller(v, "bestsellers", "no");
                                }}
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                              >
                                {/* {this.state.loading
                                  ? "Removing..."
                                  : "Best Seller"} */}
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    emoji_events
                                  </span>

                                  <span className="mongo-title">
                                    Best Seller
                                  </span>
                                </div>
                              </Button>
                            )}
                          </td>

                          {/* simple product se add krne ke liye best seller me === */}
                          {/* <td>
                            <Button
                              className="btn-round"
                              color="success"
                              type="submit"
                              style={{backgroundColor:buttonColor}}
                              onClick={() => this.bestSellerSubmit(v)}
                            >
                              {this.state.loading
                                ? "Adding..."
                                : "Add Best Seller"}
                            </Button>
                          </td> */}

                          {/* <td>
                            <Link
                              to={{
                                pathname: "/admin/updateproduct/" + v._id,
                                state: { data: v },
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="info"
                                type="submit"
                                style={{ marginRight: "10px" }}
                              >
                                Update
                              </Button>
                            </Link>
                          </td> */}

                          <td>
                            <Link
                              to={{
                                pathname: "/admin/updateproduct/" + v._id,
                                state: { data: v },
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="info"
                                type="submit"
                                // style={{ marginRight: "10px" }}
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                              >
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    Edit
                                  </span>
                                  <span className="mongo-title">Update</span>
                                </div>
                              </Button>
                            </Link>
                          </td>

                          <td>
                            <Link
                              to={{
                                pathname: "/admin/variants/" + v._id,
                                state: { data: v },
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="info"
                                type="submit"
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                              >
                                {/* Variants */}

                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    variables
                                  </span>

                                  <span className="mongo-title">Variant</span>
                                </div>
                              </Button>
                            </Link>
                          </td>

                          {/* <td>
                            <Link
                              to={{
                                pathname: "/admin/viewallratings/" + v._id,
                                state: { data: v },
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="warning"
                                type="submit"
                                style={{ marginLeft: "-33px" }}
                              >
                                Ratings
                              </Button>
                            </Link>
                          </td> */}

                          <td>
                            <Link
                              to={{
                                pathname: "/admin/viewallratings/" + v._id,
                                state: { data: v },
                              }}
                            >
                              <Button
                                className="btn-round"
                                color="warning"
                                type="submit"
                                style={{
                                  marginLeft: "-33px",
                                  width: "65px",
                                  height: "45px",
                                }}
                              >
                                <div className="mongo">
                                  <span class="material-symbols-outlined">
                                    star_rate
                                  </span>
                                  <span className="mongo-title">Rating</span>
                                </div>
                              </Button>
                            </Link>
                          </td>
                          {/* <td>
                            <Button
                              className="btn-round"
                              color="danger"
                              type="submit"
                              style={{ marginLeft: "-33px" }}
                              onClick={() => {
                                this.handleModal(v, "delete");
                              }}
                            >
                              Delete
                             
                            </Button>
                          </td> */}

                          {/* <td>
                            <Button>
                            <div className="mongo">
            
              <span className="mongo-title">Mongo DB</span>
            </div>
                            </Button>
                           </td> */}
                          <td>
                            <Button
                              className="btn-round"
                              color="danger"
                              type="submit"
                              style={{
                                marginLeft: "-33px",
                                width: "65px",
                                height: "45px",
                              }}
                              onClick={() => {
                                this.handleModal(v, "delete");
                              }}
                            >
                              <div className="mongo">
                                <span class="material-symbols-outlined">
                                  delete
                                </span>
                                <span className="mongo-title">Delete</span>
                              </div>
                            </Button>
                          </td>

                          <td>
                            {/* <a
                              href={
                                Constants.postUrls.downloadCsvProduct
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            > */}
                            {/* <Button className="btn-round" color="primary" onClick={() => {this.handleDownload(v?.name, v?.email, v?.mobile_no,v?.dob)}}>
                                Download
                              </Button> */}
                            {/* </a> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    <Modal
                      visible={this.state.visible}
                      width="600"
                      height="100"
                      effect="fadeInUp"
                      onClickAway={() => this.setState({ visible: false })}
                    >
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <Card>
                            {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                            <CardBody>
                              <div style={{ textAlign: "center" }}>
                                <h3>Are You Sure You Want to Delete It?</h3>
                                {this.state.notifyDelete ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      Product Deleted Successfuly!
                                    </span>
                                  </div>
                                ) : null}
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Col xs={12} sm={12} md={4}>
                                    <br />
                                    {/* <div style={{alignItems:"center",justifyContent:"center",flex:"right",marginLeft:"20px"}}> */}
                                    <Button
                                      className=""
                                      color="warning"
                                      onClick={() => {
                                        this.handleDelete(this.state.rowId);
                                      }}
                                    >
                                      {this.state.delLoading
                                        ? "Deleting..."
                                        : "Yes"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="success"
                                      onClick={() => {
                                        this.setState({ visible: false });
                                      }}
                                    >
                                      No
                                    </Button>
                                    {/* </div> */}
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>

                    <Modal
                      visible={this.state.visibleTrending}
                      width="600"
                      height="100"
                      effect="fadeInUp"
                      onClickAway={() =>
                        this.setState({ visibleTrending: false })
                      }
                    >
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <Card>
                            {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
                            <CardBody>
                              <div>
                                <h3 style={{ textAlign: "center" }}>
                                  Are You Sure You Want to{" "}
                                  {this.state.trendingStatus === "yes"
                                    ? "Trend"
                                    : "Untrend"}{" "}
                                  It?
                                </h3>
                                {this.state.notifyTrending ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      {this.state.trendingStatus === "yes"
                                        ? "Added To Trending Successfuly!"
                                        : "Removed From Trending Successfuly!"}
                                    </span>
                                  </div>
                                ) : null}
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Col xs={12} sm={12} md={4}>
                                    <br />
                                    <Button
                                      className=""
                                      color="success"
                                      onClick={() => {
                                        this.handleTrending(this.state.trendId);
                                      }}
                                    >
                                      {this.state.trending
                                        ? this.state.trendingStatus === "yes"
                                          ? "Trending..."
                                          : "Untrnding..."
                                        : "Yes"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="danger"
                                      onClick={() => {
                                        this.setState({
                                          visibleTrending: false,
                                        });
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>

                    {/* addbest seller==== */}
                    <Modal
                      visible={this.state.visibleBestSellers}
                      width="600"
                      height="100"
                      effect="fadeInUp"
                      onClickAway={() =>
                        this.setState({ visibleBestSellers: false })
                      }
                    >
                      <Row>
                        <Col xs={12} sm={12} md={12}>
                          <Card>
                            <CardBody>
                              <div>
                                <h3 style={{ textAlign: "center" }}>
                                  Are You Sure You Want to{" "}
                                  {this.state.bestSellersStatus === "yes"
                                    ? "Add Best Seller"
                                    : "Remove Best Seller"}{" "}
                                  It?
                                </h3>
                                {this.state.notifyBestSellers ? (
                                  <div>
                                    <span style={{ color: "green" }}>
                                      {this.state.trendingStatus === "yes"
                                        ? "Added To Add Best Sller Successfuly!"
                                        : "Removed From Add Best Seller Successfuly!"}
                                    </span>
                                  </div>
                                ) : null}
                                <Row
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Col xs={12} sm={12} md={4}>
                                    <br />
                                    <Button
                                      className=""
                                      color="success"
                                      onClick={() => {
                                        this.handleBestseller(
                                          this.state.bestSellerId
                                        );
                                      }}
                                    >
                                      {this.state.bestsellers
                                        ? this.state.bestSellersStatus === "yes"
                                          ? "Best seller..."
                                          : "Un Best seller..."
                                        : "Yes"}
                                    </Button>
                                    <Button
                                      className=""
                                      color="danger"
                                      onClick={() => {
                                        this.setState({
                                          visibleBestSellers: false,
                                        });
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Modal>
                  </Table>

                  {this.state.data?.length === 0 ? (
                    <div>
                      <h6
                        style={{
                          textAlign: "center",
                          marginTop: "30px",
                          fontSize: "14px",
                        }}
                      >
                        No Data Found!
                      </h6>
                    </div>
                  ) : (
                    <div style={{ marginTop: "20px" }}>
                      <div style={{ float: "left" }}>
                        Showing {this.state.from} to {this.state.to} of{" "}
                        {this.state.total} entries.
                      </div>

                      <div style={{ float: "right" }}>
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={this.prevPages}
                              tabIndex="-1"
                            >
                              <i className="fa fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>

                          {this.state.filteredBtnIdArray.map((v, i) => (
                            <PaginationItem
                              className={
                                // this.state.active === v ? "active" : ""
                                currentPage === v ? "active" : ""
                              }
                              key={i}
                            >
                              <PaginationLink
                                onClick={() => {
                                  this.currentPage(v);
                                }}
                              >
                                {v}
                              </PaginationLink>
                            </PaginationItem>
                          ))}

                          <PaginationItem>
                            <PaginationLink onClick={this.nextPages}>
                              <i className="fa fa-angle-right" />
                              <span className="sr-only">Next</span>
                            </PaginationLink>
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Products;
