import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import MultiSelect from "react-multi-select-component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Added By Nehal Prasad on 29/-03/2023
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Grid, Box, Modal } from "@material-ui/core";

class AddProduct extends Component {
  state = {
    name: "",
    commission: "",
    categories: [],
    productsFor: [],
    productFor: "",
    category: "",
    productIds: [],
    products: [],
    filteredProductsFor: [],
    filteredSubCategories: [],
    subCategories: [],
    deliveryModes: [],
    deliveryMode: "",
    subCategory: "",
    overview: "",
    note: "",
    charge: "",
    specification: "",
    taxType: "",
    taxTypes: [],
    // price: "",
    // offerType: "",
    value: "",
    quantity: "",
    product_exchange: "",
    vendors: [],
    // image: [],
    image: "",
    video: [],
    customizeImage: [],
    // description: "",
    short_description: "",
    customizeProduct: "no",
    vendor: "",
    customize: false,
    videoAdded: false,
    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
    product_return: "",

    // Added by Nehal Prasad on 29/03/2023
    images1: "",
    pics: [],
    selectedFiles: [],

    myArray: [],
    myArrayLength: "",

    selectedImage: "",
    currenIndex: 0,

    imageCrop: {
      unit: "%",
      x: "",
      y: "",
      width: 60,
      height: 70,
    },

    croppedImage: [],
    firstpic: "",
    secondpic: "",
    thirdpic: "",
    fourthpic: "",
    fifthpic: "",
    modalVisible: "false",
    finalImage: [],
  };
  componentDidMount() {
    axios
      .get(
        Constants.getUrls.printTypes +
          "?limit=50&skip=0&page=0" +
          "&is_delete=no&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp.data);
        resp.data.docs.forEach((v) => {
          this.state.products.push({
            label: v.name,
            value: v._id,
          });
        });
        this.setState({
          products: this.state.products,
        });
      });
    // Super Categories API
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
      });
    //  Products For API
    // axios
    //   .get(Constants.getUrls.productsFor + "?limit=50&skip=1&page=1")
    //   .then((resp) => {
    //     // console.log(resp.data.data);
    //     this.setState({
    //       productsFor: resp.data.docs,
    //     });
    //   });

    // Vendors API
    axios
      .get(
        Constants.getUrls.vendors + "?limit=50&skip=0&page=0" + "&is_delete=no"
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          vendors: resp.data.docs,
        });
      });
    // Tax Types API
    axios
      .get(
        Constants.getUrls.taxTypes + "?limit=50&skip=0&page=0" + "&is_delete=no"
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          taxTypes: resp.data.docs,
        });
      });
    // Delivery Modes API
    axios
      .get(
        Constants.getUrls.deliveryModes +
          "?limit=50&skip=0&page=0" +
          "&is_delete=no"
      )
      .then((resp) => {
        // console.log(resp.data);
        this.setState({
          deliveryModes: resp.data.docs,
        });
      });
  }
  handleCatgory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=ACTIVE" +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };
  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        // console.log(this.state.short_description);
      }
    );
  };
  handleSubCategory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.subCategories +
              "?limit=50&page=1&status=ACTIVE" +
              "&category=" +
              this.state.category +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              subCategories: resp.data.docs,
            });
          });
      }
    );
  };
  handleProductFor = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        //  Products For API
        axios
          .get(
            Constants.getUrls.productsFor +
              "/" +
              this.state.category +
              "?limit=50&skip=1&page=1" +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp);
            this.setState({
              productsFor: resp.data,
            });
          });
      }
    );
  };
  // handleFile = (e) => {
  //   this.setState({ [e.target.name]: e.target.files });
  // };

  // handleFile = (e) => {
  //   // console.log(e.target.files);
  //   this.setState({ [e.target.name]: e.target.files });
  //   // console.log(this.state.image);
  // };
  // handleVideo = (e) => {
  //   this.setState({ [e.target.name]: e.target.files[0], videoAdded: true });
  // };
  handleSubmit = async (e) => {
    e.preventDefault();
    // if (this.state.productIds.length===0) {
    //   this.setState({warn:true});
    // } else {
    this.setState({
      loading: true,
    });
    let prodIds = [];
    console.log(this.state.productIds);
    this.state.productIds.forEach((v) => {
      prodIds.push(v.value);
    });
    let fd = new FormData();
    fd.append("product_for", this.state.productFor);
    // for (let i = 0; i < this.state.image.length; i++) {
    //   fd.append("image", this.state.image[i]);
    // }

    // if (this.state.videoAdded) {
    //   fd.append("video", this.state.video);
    // }
    fd.append("name", this.state.name);
    fd.append("superCatId", this.state.super_category);
    fd.append("catId", this.state.category);
    fd.append("subcatId", this.state.subCategory);
    // fd.append("vendorId", this.state.vendor);
    // fd.append("vendor_commission", this.state.commission);
    // fd.append("delivery_modeId", this.state.deliveryMode);
    fd.append("delivery_amount", this.state.charge);
    if (this.state.specification !== "") {
      fd.append("specification", this.state.specification);
    }
    if (this.state.note !== "") {
      fd.append("note", this.state.note);
    }
    if (this.state.overview !== "") {
      fd.append("product_overview", this.state.overview);
    }
    fd.append("tax_type", this.state.taxType);
    // fd.append("price", this.state.price);
    // if (this.state.offerType !== "") {
    //   fd.append("offer_type", this.state.offerType);
    // }
    // if (this.state.value !== "") {
    //   fd.append("offer_value", this.state.value);
    // }
    // fd.append("printIds", JSON.stringify(prodIds));
    // fd.append("product_quantity", this.state.quantity);
    fd.append("product_return", this.state.product_return);
    fd.append("product_exchange", this.state.product_exchange);

    // let payLoad = {
    //   // super_category: this.state.super_category,
    //   // category: this.state.category,
    //   // title: this.state.name,
    //   short_description: this.state.short_description
    // };
    // fd.append("customize_product", this.state.customizeProduct);
    //   if (this.state.customizeProduct === "yes") {
    //   fd.append("customize_product_image", this.state.customizeImage);
    fd.append("short_description", this.state.short_description);
    // fd.append("image", this.state.image);

    // if (this.state.image) {
    //   for (let i = 0; i < this.state.image.length; i++) {
    //     fd.append("image", this.state.image[i]);
    //   }
    // }
    // }

    axios.post(Constants.getUrls.products, fd).then((resp) => {
      console.log(resp);
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warning: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warning: false,
          });
        }, 1000);
      }
    });
    // };
  };

  handleFinish = (e) => {
    this.setState({
      modalVisible: false,
    });

    const { croppedImage, firstpic, secondpic, thirdpic, fourthpic, fifthpic } =
      this.state;

    if (firstpic) {
      croppedImage.push(firstpic);
      console.log(firstpic);
    }
    if (secondpic) {
      croppedImage.push(secondpic);
      console.log(secondpic);
      console.log(typeof secondpic);
    }
    if (thirdpic) {
      croppedImage.push(thirdpic);
    }
    if (fourthpic) {
      croppedImage.push(fourthpic);
    }
    if (fifthpic) {
      croppedImage.push(fifthpic);
    }

    // const finalObj = [...firstpic, ...secondpic, ...thirdpic];

    // console.log(finalObj)

    // this.setState({
    //   finalImage:finalObj
    // })

    // console.log(croppedImage)
    // const objectkeys = Object.keys(croppedImage)

    // console.log(objectkeys)

    // const objectValue = Object.values(croppedImage)

    // console.log(objectValue)
  };

  refForImages = (pics) => {
    pics.map((key, index) => {
      const obj = URL.createObjectURL(key);
      console.log(obj);
      this.state.myArray.push(obj);
    });

    console.log(this.state.myArray);
    const text = this.state.myArray;
    console.log(text.length);
    this.setState({
      myArrayLength: text.length,
    });
  };

  onImageLoaded = (image) => {
    // console.log(image)
    this.imageRef = image;
  };

  onImageCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // console.log(crop)
    // this.setState({ crop: percentCrop });
    this.setState({ imageCrop: crop });
    // console.log(this.state.imageCrop)
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  async makeClientCrop(crop) {
    // console.log(crop)
    // console.log(this.imageRef)
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      let obj = [];
      obj.push(croppedImageUrl);

      if (this.state.currenIndex === 0) {
        this.setState({
          firstpic: obj.splice(-1),
        });
      }

      if (this.state.currenIndex === 1) {
        this.setState({
          secondpic: obj.splice(-1),
        });
      }

      if (this.state.currenIndex === 2) {
        this.setState({
          thirdpic: obj.splice(-1),
        });
      }
      if (this.state.currenIndex === 3) {
        this.setState({
          fourthpic: obj.splice(-1),
        });
      }
      if (this.state.currenIndex === 4) {
        this.setState({
          fifthpic: obj.splice(-1),
        });
      }
    }
  }

  // handleImageFile = (e) => {

  //   console.log(e.target.files)
  //   const selectedFiles = Array.from(e.target.files)
  //   console.log(selectedFiles)

  //   if(selectedFiles.length > 0){
  //     this.setState({
  //       modalVisible: true
  //     })
  //   }
  //   e.persist();
  //   if (e.target.files[0]) {
  //     this.setState(
  //       {
  //         [e.target.name]: e.target.files[0],
  //         pics: e.target.files,
  //         selectedFiles: selectedFiles,
  //         currentIndex: 0
  //       },
  //       () => {
  //         this.refForImages(selectedFiles);
  //       }
  //     );
  //   }
  // }

  handleImage = (e) => {
    console.log(e.target.files);
    const selectedFiles = Array.from(e.target.files);
    console.log(selectedFiles);

    if (selectedFiles.length > 0) {
      this.setState({
        modalVisible: true,
      });
    }
    e.persist();
    if (e.target.files[0]) {
      this.setState(
        {
          [e.target.name]: e.target.files[0],
          pics: e.target.files,
          selectedFiles: selectedFiles,
          currentIndex: 0,
        },
        () => {
          this.refForImages(selectedFiles);
        }
      );
    }
  };

  handleNext = () => {
    this.setState({
      currenIndex: (this.state.currenIndex + 1) % this.state.myArrayLength,
      imageCrop: {
        unit: "%",
        x: "",
        y: "",
        width: 60,
        height: 70,
      },
    });
  };

  handlePrev = () => {
    this.setState({
      currenIndex: (this.state.currenIndex - 1) % this.state.myArrayLength,
      imageCrop: {
        unit: "%",
        x: "",
        y: "",
        width: 60,
        height: 70,
      },
    });
  };

  render() {
    const {
      myArray,
      myArrayLength,
      modalVisible,
      currenIndex,
      firstpic,
      secondpic,
      thirdpic,
      fourthpic,
      fifthpic,
    } = this.state;
    if (this.state.redirect) {
      return <Redirect to={"/admin/products"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Product Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>This Product Already Added!</strong>
                    {/* <strong>Some Error Occured!</strong> */}
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Product</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleCatgory}
                            >
                              <option disabled selected="true" value="">
                                Select an Option
                              </option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="category"
                              required={true}
                              onChange={this.handleSubCategory}
                            >
                              <option disabled selected="true" value="">
                                Select an Option
                              </option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Sub Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="subCategory"
                              required={true}
                              onChange={this.handleProductFor}
                            >
                              <option disabled selected="true" value="">
                                Select Category First!
                              </option>
                              {this.state.subCategories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Select Tribe</label>
                            <br />
                            <select
                              className="form-control"
                              name="productFor"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">
                                Select Sub Category First!
                              </option>
                              {this.state.productsFor.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.category_name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        {/* VENDOR AND VENDOR Comission */}
                        {/*                         
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Vendor</label>
                            <br />
                            <select
                              className="form-control"
                              name="vendor"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.vendors.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Vendor Commision</label>
                            <Input
                              placeholder="Vendor Commision"
                              name="commission"
                              onChange={this.handleInput}
                              required={true}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Delivery Mode</label>
                            <br />
                            <select
                              className="form-control"
                              name="deliveryMode"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.deliveryModes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.delivery_mode}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Tax Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="taxType"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.taxTypes.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.tax_type}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        {/* <Col className="pr-1" md="3">
                          <label htmlFor="Print Types">Print Types</label>
                          <MultiSelect
                            disableSearch
                            options={this.state.products}
                            value={this.state.productIds}
                            onChange={(data) => {
                              this.setState({ productIds: data });
                            }}
                            labelledBy={"Print Types"}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col> */}

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Quantity</label>
                            <Input
                              placeholder="Quantity"
                              name="quantity"
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Price</label>
                            <Input
                              placeholder="Price"
                              name="price"
                              onChange={this.handleInput}
                              required={true}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="offerType"
                              onChange={this.handleInput}
                            >
                              <option value="">Select Option</option>
                              {["amount", "percent"].map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Offer Value</label>
                            <Input
                              placeholder="Offer Value"
                              name="value"
                              onChange={this.handleInput}
                              type="float"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Delivery Charge</label>
                            <Input
                              placeholder="Delivery Charge"
                              name="charge"
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Short Description</label>
                            <Input
                              placeholder="Short Description"
                              value={this.state.short_description}
                              name="short_description"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Product Return</label>
                            <select
                              className="form-control"
                              name="product_return"
                              // required={true}
                              value={this.state.product_return}
                              onChange={this.handleInput}
                            >
                              <option value="Product_Return">
                                Product_Return
                              </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Product Exchange</label>
                            <select
                              className="form-control"
                              name="product_exchange"
                              // required={true}
                              value={this.state.product_exchange}
                              onChange={this.handleInput}
                            >
                              <option value="Product_Exchange">
                                Product_Exchange
                              </option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col>

                        {/* <Col className="pr-1" md="3">
                        <label>Image</label>
                        <br />
                        <input
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          required={true}
                          accept="image/*"
                          multiple
                        />
                      </Col> */}

                        {/* <Col style={{ marginTop: "8px" }} md="3">
                          <label>Video</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleVideo}
                            name="video"
                            accept="video/*"
                          />
                        </Col> */}

                        <Col style={{ marginTop: "8px" }} md="6">
                          <Grid container spacing={2}>
                            {/* <Grid item sm={4}>
            <label>Image</label>
            <br />
              <input
                type="file"
                accept="image/*"
                name='images1'
                onChange={this.handleImage}
                multiple
              />
            </Grid> */}
                            {modalVisible === true ? (
                              <Modal
                                open={modalVisible}
                                onClose={() => {
                                  this.setState({
                                    modalVisible: false,
                                    pics: null,
                                    selectedImage: null,
                                  });
                                }}
                              >
                                <Box
                                  className="modal-size"
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "62%",
                                    height: "72%",
                                    boxShadow: 24,
                                    p: 4,
                                    overflowY: "scroll",
                                  }}
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Row
                                    style={{
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 20,
                                      width: 400,
                                      height: 400,
                                    }}
                                  >
                                    {/* <Col> */}
                                    <ReactCrop
                                      src={myArray[currenIndex]}
                                      crop={this.state.imageCrop}
                                      ruleOfThirds
                                      onImageLoaded={this.onImageLoaded}
                                      onComplete={this.onImageCropComplete}
                                      onChange={this.onCropChange}
                                      crossorigin="anonymous"
                                      aspect={4 / 3}
                                    />
                                    {/* </Col> */}
                                    {/* <Col>
                          {firstpic && currenIndex === 0? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={firstpic}
                              />
                            : " "}
                            {secondpic && currenIndex === 1? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={secondpic}
                              />
                            : " "}
                            {thirdpic && currenIndex === 2? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={thirdpic}
                              />
                            : " "}
                            {fourthpic && currenIndex === 3? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={fourthpic}
                              />
                            : " "}
                            {fifthpic && currenIndex === 4? 
                                <img
                                alt="Crop"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  marginLeft: "1rem",
                                }}
                                src={fifthpic}
                              />
                            : " "}

                    
                    </Col> */}
                                  </Row>

                                  {myArrayLength === currenIndex + 1 && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      size="medium"
                                      style={{
                                        position: "absolute",
                                        top: "95%",
                                        right: "41%",
                                        transform: "translate(-50%, -50%)",
                                        zIndex: "10",
                                      }}
                                      type="submit"
                                      onClick={this.handleFinish}
                                    >
                                      Finish
                                    </Button>
                                  )}

                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      position: "absolute",
                                      top: "95%",
                                      right: "51%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                    }}
                                    onClick={this.handlePrev}
                                    disabled={currenIndex === 0}
                                  >
                                    Prev
                                  </Button>

                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "10%",
                                      right: "22%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                      fontSize: 30,
                                      color: "black",
                                    }}
                                  >
                                    <h1
                                      style={{
                                        textAlign: "center",
                                        color: "black",
                                        fontSize: "2rem",
                                      }}
                                    >
                                      Crop Your Image
                                    </h1>
                                  </div>

                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    style={{
                                      position: "absolute",
                                      top: "95%",
                                      right: "33%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: "10",
                                    }}
                                    onClick={this.handleNext}
                                    disabled={currenIndex === myArrayLength - 1}
                                  >
                                    Next
                                  </Button>
                                </Box>
                              </Modal>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Col>

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Return Product</label>
                            <select
                              className="form-control"
                              name="is_return"
                              value={this.state.is_return}
                              onChange={this.handleInput}
                            >
                              <option value="yes">Yes</option>

                              <option value="no">No</option>
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col md="12">
                          <label>Product Overview</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                overview: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Note</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                note: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Specification</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                specification: data,
                              });
                            }}
                          />
                          <br />
                        </Col>

                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Customize Product</label>
                            <br />
                            <select
                              className="form-control"
                              name="customizeProduct"
                              value={this.state.customizeProduct}
                              onChange={this.handleInput}
                            >
                              {["no", "yes"].map((v, i) => (
                                <option key={i} value={v}>
                                  {v}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      {/* {this.state.customizeProduct === "yes" ? (<Row>
                        <Col md="12">
                          <label>Image</label>
                          <br />
                          <input
                            type="file"
                            onChange={this.handleFile}
                            name="customizeImage"
                          />
                        </Col>
                        <Col md="12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                description: data
                              });
                            }}
                          />
                          <br />
                        </Col>

                      </Row>) : null} */}
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Product"}
                          </Button>
                          <Link to="/admin/products">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddProduct;
