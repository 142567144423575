// import React, { Component } from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Row,
//   Col,
//   Button,
//   FormGroup,
//   Form,
//   Input,
//   UncontrolledAlert
// } from "reactstrap";
// import axios from "axios";
// import Constants from "../variables/Constants";
// import { Redirect, Link } from "react-router-dom";
// class AddDeliveryPartner extends Component {
//   state = {
//     name: "",
//     redirect: false,
//     warn: false,
//     notification: false,
//     loading: false
//   };
//   handleInput = e => {
//     this.setState({
//       [e.target.name]: e.target.value
//     });
//   };
//   handleSubmit = e => {
//     this.setState({
//       loading: true
//     });
//     e.preventDefault();
//     // let payLoad = {
//     //   delivery_mode: this.state.name
//     // };
//     let fd = new FormData();

//     fd.append("title", this.state.name);
//     axios
//       .post(Constants.postUrls.addDeliveryPartners, fd)
//      .then((resp) => {
//        console.log(resp);
//         if (resp.data.status === "success") {
//           setTimeout(() => {
//             this.setState({
//               redirect: true
//             });
//           }, 1000);
//           this.setState({
//             notification: true,
//             loading: false
//           });
//         } else {
//           this.setState({
//               warn:true,
//               loading:false
//           });
//           setTimeout(() => {
//             this.setState({
//               warn: false
//             });
//           }, 1000);
//         }
//       });
//   };
//   render() {
//     if (this.state.redirect) {
//       return <Redirect to={"/admin/deliverypartners"} />;
//     } else {
//       return (
//         <>
//           <div className="content">
//             {this.state.notification ? (
//               <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
//               <UncontrolledAlert color="success" fade={false}>
//               <span className="alert-inner--icon">
//                 <i className="ni ni-like-2" />
//               </span>{" "}
//               <span className="alert-inner--text">
//                 <strong>Delivery Partner Added!</strong>
//               </span>
//             </UncontrolledAlert>
//             </div>
//             ) : null}
//             {this.state.warn ? (
//               <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
//               <UncontrolledAlert color="danger" fade={false}>
//               <span className="alert-inner--icon">
//                 <i className="ni ni-dislike-2" />
//               </span>{" "}
//               <span className="alert-inner--text">
//                 <strong>Some Error Occured!</strong>
//               </span>
//             </UncontrolledAlert>
//             </div>
//             ) : null}
//             <Row>
//               <Col md="12">
//                 <Card>
//                   <CardHeader>
//                     <CardTitle tag="h4">Add Delivery Partner</CardTitle>
//                   </CardHeader>
//                   <CardBody>
//                     <Form onSubmit={this.handleSubmit}>
//                       <Row>
//                         <Col className="pr-1" md="6">
//                           <FormGroup>
//                             <label>Delivery Partner</label>
//                             <Input
//                               placeholder="Delivery Partner"
//                               name="name"
//                               onChange={this.handleInput}
//                               required={true}
//                               type="text"
//                             />
//                           </FormGroup>
//                         </Col>
//                       </Row>
//                       <Row>
//                         <Col className="pr-1" md="6">
//                           <Button
//                             className="btn-round"
//                             color="success"
//                             type="submit"
//                           >
//                             {this.state.loading ? "Adding..." : "Add Delivery partner"}
//                           </Button>
//                           <Link to="/admin/deliverypartners">
//                             <Button className="btn-round" color="danger">
//                               Cancel
//                             </Button>
//                           </Link>
//                         </Col>
//                       </Row>
//                     </Form>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           </div>
//         </>
//       );
//     }
//   }
// }

// export default AddDeliveryPartner;\



import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import Modal from "react-awesome-modal";
import init from "../Helper/WindowToken";
const fd = new FormData();
export default class AddDeliveryPartner extends Component {
  state = {
    name: "",
    image: "",
    description: "",
    notification: false,
    loading: false,
    message: "",
  };

  handleFile = (e) => {
    console.log(e.target.files);
    this.setState({ [e.target.name]: e.target.files[0] });
    console.log(this.state.image);
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  



  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   title: this.state.name,
    // };
    let fd = new FormData();

    fd.append("title", this.state.name);
    fd.append("image", this.state.image);
    axios
      .post(Constants.postUrls.addDeliveryPartners, fd)
      .then((resp) => {
        console.log(resp, "romendra");
        fd.delete("image")
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/deliverypartners"} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  {/* <strong> {this.state.message} </strong> */}
                  <strong>Delivery Partner Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

{this.state.warn ? (
              <div style={{width:"250px",position:"relative",left:"40%",top:"7px"}}>
              <UncontrolledAlert color="danger" fade={false}>
              <span className="alert-inner--icon">
                <i className="ni ni-dislike-2" />
              </span>{" "}
              <span className="alert-inner--text">
                {/* <strong>Some Error Occured!</strong> */}
                <strong>This DeliveryPartner Already Added!</strong>
              </span>
            </UncontrolledAlert>
            </div>
            ) : null}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Add DeliveryPartner</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            placeholder="Name"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col>
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            placeholder="Description"
                            value={this.state.description}
                            name="description"
                            onChange={this.handleInput}
                            required={true}
                            type="text"
                          />
                        </FormGroup>
                      </Col> */}
                      <Col className="pr-1" md="6">
                        <label>Image</label>
                        <br />
                        <input
                          type="file"
                          name="image"
                          onChange={this.handleFile}
                          // required={true}
                          accept="image/*"
                        />
                      </Col>

                      <Col className="pr-1" md="6">
                          <label>Uploaded Image</label>
                          <br />
                          <img
                            src={Constants.imgUrl + this.state.image}
                            alt="image"
                          />
                        </Col> 
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading ? "Adding..." : "Add DeliveryPartner"}
                        </Button>
                        <Link to="/admin/deliverypartners">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
