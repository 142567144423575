import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
class UpdateMobileSlider extends Component {
  state = {
    name: "",
    link: "",
    note: "",
    type: "",
    image: "",
    subCategory: "",
    category: "",
    subCategories: [],
    categories: [],
    catVisible: false,
    subCatVisible: false,
    description: "",
    redirect: false,
    imgUpdated: false,
    warn: false,
    notification: false,
    loading: false,
    // image Preview
    imageFile: "",
    photoUrl: "",
    imagePreview: false,
  };
  componentDidMount() {
    axios
      .get(Constants.getUrls.categories + "?limit=20&skip=0&page=0")
      .then((resp) => {
        // console.log(resp.data.data);
        this.setState({
          categories: resp.data.docs,
        });
      });
    axios
      .get(Constants.getUrls.subCategories + "?limit=50&skip=0&page=0")
      .then((resp) => {
        // console.log(resp.data.data);
        this.setState({
          subCategories: resp.data.docs,
        });
      });
    let data = this.props.location.state.data;
    // console.log(data);
    // if (data.link !== "null") {
    //     let url =  data.link !== "null" && new URL(data.link);
    //     let catId = url !== null && url.searchParams.get("catId");
    //     let subCatId = url !== null && url.searchParams.get("subCatId");
    //     this.setState({
    //         category: catId ? catId : "",
    //         subCategory: subCatId ? subCatId : "",
    //         type: catId ? "Category" : subCatId ? "Subcategory" : "",
    //         catVisible: catId ? true : false,
    //         subCatVisible: subCatId ? true : false,
    //     });
    // }
    this.setState({
      name: data.title,
      link: data.link,
      description: data.description ? data.description : "",
      note: data.note ? data.note : "",
      id: data._id,
      image: data.image,
    });
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    console.log(e.target.files[0]);
    this.setState({ [e.target.name]: e.target.files[0], imgUpdated: true });
  };
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let fd = new FormData();
    if (this.state.imgUpdated) {
      fd.append("image", this.state.image);
    }

    fd.append("link", this.state.link);
    //link se sath category & subcategory relation============
    // if (this.state.category !== "" && this.state.catVisible) {
    //     fd.append("link", "http://fakeurl.com?catId=" + this.state.category);
    // }
    // if (this.state.subCategory !== "" && this.state.subCatVisible) {
    //     fd.append("link", "http://fakeurl.com?subCatId=" + this.state.subCategory);
    // }

    if (this.state.description !== "") {
      fd.append("description", this.state.description);
    }
    if (this.state.note !== "") {
      fd.append("note", this.state.note);
    }
    fd.append("title", this.state.name);
    axios
      .put(Constants.getUrls.mobileHomeSliders + "/" + this.state.id, fd)
      .then((resp) => {
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/mobilehomesliders"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Mobile Home Slider Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                    {/* <strong>Some Error Occured!</strong> */}
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Mobile Home Slider</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              value={this.state.name}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ marginTop: "8px" }} md="6">
                          <Row>
                            <Col style={{ marginTop: "8px" }} md="6">
                              <label>Image(1143*350)</label>
                              <br />
                              <input
                                type="file"
                                onChange={(e) => {
                                  this.handleFile(e);
                                  this.handlePreview(e);
                                  this.setState({ imagePreview: true });
                                }}
                                // required={true}
                                name="image"
                              />
                            </Col>
                            {this.state.imagePreview ? (
                              <Col style={{ marginTop: "8px" }} md="6">
                                <label>Image Preview</label>
                                <br />
                                <img
                                  width={100}
                                  src={this.state.photoUrl}
                                  alt=""
                                />
                              </Col>
                            ) : (
                              <Col style={{ marginTop: "8px" }} md="6">
                                <label>Uploaded Image:</label>
                                <br />
                                <img
                                  width={100}
                                  src={Constants.imgUrl + this.state.image}
                                  alt=""
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col className="pr-1" md="12">
                          <FormGroup>
                            <label>Link</label>
                            <Input
                              placeholder="Link"
                              name="link"
                              onChange={this.handleInput}
                              value={this.state.link}
                              required={true}
                              type="link"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <label>Note</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={this.props.location.state.data.note}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                note: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col md="12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            data={this.props.location.state.data.description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                description: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Updating..."
                              : "Update Mobile Home Slider"}
                          </Button>
                          <Link to="/admin/mobilehomesliders">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateMobileSlider;
