// import React, { Component } from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Row,
//   Col,
//   Button,
//   FormGroup,
//   Form,
//   Input,
//   UncontrolledAlert,
// } from "reactstrap";
// import axios from "axios";
// import Constants from "../variables/Constants";
// import { Redirect, Link } from "react-router-dom";
// import MultiSelect from "react-multi-select-component";
// import ReactDatetime from "react-datetime";
// class AddCouponCode extends Component {
//   state = {
//     name: "",
//     code: "",
//     discount: "",
//     strDate: "",
//     endDate: "",
//     type: "",
//     productIds: [],
//     products: [],
//     warn1: false,
//     warn2: false,
//     warn3: false,
//     redirect: false,
//     warn: false,
//     notification: false,
//     loading: false,
//     data: [],
//     visible: false,

//     warning: false,
  
//     super_categories: [],
//     super_category: "",
//     categories: [],
//     category: "",
//     sub_categories: [],
//     sub_category: "",
//     search: "",
//     all_filter_product: [],
//     selectedID: "",
//   };
//   componentDidMount() {
//     // axios
//     //   .get(Constants.getUrls.products + "?limit=500&skip=0&page=0")
//     //   .then(resp => {
//     //     // console.log(resp.data);
//     //     resp.data.docs.forEach((v) => {
//     //       this.state.products.push({
//     //         label: v.name,
//     //         value: v._id,
//     //       });
//     //     });
//     //     this.setState({
//     //       products: this.state.products,
//     //     });
//     //   });
 
//     // bestseller product=======//


//     // axios
//     // .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1")
//     // .then((resp) => {
//     //   // console.log(resp);
//     //   this.setState({
//     //     super_categories: resp.data.superCat.docs,
//     //   });
//     // });
//   }


//   // handleCatgory = (e) => {
//   //   this.setState(
//   //     {
//   //       [e.target.name]: e.target.value,
//   //       category: ""
//   //     },
//   //     () => {
//   //       axios
//   //         .get(
//   //           Constants.getUrls.categories +
//   //             "?limit=50&page=1&status=active" +
//   //             `&super_category=` +
//   //             this.state.super_category
//   //         )
//   //         .then((resp) => {
//   //           // console.log(resp);
//   //           this.setState({
//   //             categories: resp.data.docs,
//   //           });
//   //         });
//   //     }
//   //   );
//   // };

//   // handleSubCategory = (e) => {
//   //   this.setState(
//   //     {
//   //       [e.target.name]: e.target.value,
//   //     },
//   //     () => {
//   //       axios
//   //         .get(
//   //           Constants.getUrls.subCategories +
//   //             "?limit=50&page=1&status=active" +
//   //             "&category=" +
//   //             this.state.category +
//   //             `&super_category=` +
//   //             this.state.super_category
//   //         )
//   //         .then((resp) => {
//   //           console.log(resp);
//   //           this.setState({
//   //             sub_categories: resp.data.docs,
//   //           });
//   //         });
//   //     }
//   //   );
//   // };
//   // handleSearch = (e) => {
//   //   this.setState(
//   //     {
//   //       [e.target.name]: e.target.value,
//   //     },
//   //     () => {
//   //       console.log(this.state.search);
//   //       axios
//   //         .get(
//   //           Constants.getUrls.productSearch +
//   //             "?super_category=" +
//   //             this.state.super_category +
//   //             "&category=" +
//   //             this.state.category +
//   //             "&sub-category=" +
//   //             this.state.sub_category
//   //         )
//   //         .then((resp) => {
//   //           // console.log(resp);
//   //           if (resp.data.getAllProduct.docs.length == 0) {
//   //             this.setState({
//   //               all_filter_product: [
//   //                 {
//   //                   name: "No Product Found!",
//   //                   _id: "0",
//   //                 },
//   //               ],
//   //             });
//   //           }
//   //           if (resp.data.getAllProduct.docs.length > 0) {
//   //             this.setState({
//   //               all_filter_product: resp.data.getAllProduct.docs,
//   //             });
//   //           }
//   //         });
//   //     }
//   //   );
//   // };




//   handleDate = (e, type) => {
//     let year = e._d.getFullYear().toString();
//     let month = (e._d.getMonth() + 101).toString().substring(1);
//     let day = (e._d.getDate() + 100).toString().substring(1);
//     if (type === "strDate") {
//       this.setState({
//         strDate: year + "-" + month + "-" + day,
//       });
//     } else {
//       this.setState({
//         endDate: year + "-" + month + "-" + day,
//       });
//     }
//   };
//   handleInput = (e) => {
//     this.setState({
//       [e.target.name]: e.target.value,
//     });
//   };
//   handleSubmit = (e) => {
//     e.preventDefault();
//     if (this.state.productIds.length === 0) {
//       this.setState({warn1:true});
//     } else
//     if (this.state.strDate === "") {
//       this.setState({ warn2: true });
//     } else if (this.state.endDate === "") {
//       this.setState({ warn3: true });
//     } else {
//       this.setState({
//         loading: true,
//       });
//       let prodIds = [];
//       console.log(this.state.productIds);
//       if (this.state.productIds.length > 0) {
//         this.state.productIds.forEach((v) => {
//           prodIds.push(v.value);
//         });
//       }
     
//       // if (this.state.selectedID === "") {
//       //   this.setState({ warn: true });
//       // } else {
//       //   this.setState({
//       //     loading: true,
//       //   });
//       // let products = [];
//       // products.push(this.state.selectedID);
//       let payLoad = {
//         coupon_name: this.state.name,
//         coupon_code: this.state.code,
//         offer_type: this.state.type,
//         discount: this.state.discount,
//         start_date: this.state.strDate,
//         end_date: this.state.endDate,
//         // prodIds,
//         // super_category: this.state.super_category,
//         // category: this.state.category,
//         // sub_category: this.state.sub_category,
//         // products,
//       };
//       console.log(payLoad);
//       axios.post(Constants.getUrls.coupons, payLoad).then((resp) => {
//         if (resp.data.status === "success") {
//           setTimeout(() => {
//             this.setState({
//               redirect: true,
//             });
//           }, 1000);
//           this.setState({
//             notification: true,
//             loading: false,
//           });
//         } else {
//           this.setState({
//             warn: true,
//             loading: false,
//           });
//           setTimeout(() => {
//             this.setState({
//               warn: false,
//             });
//           }, 1000);
//         }
//       });
//     }
//   };
//   // productSearch = () => {
//   //   this.setState({ searching: true, visible: true });
//   //   axios
//   //     .post(Constants.postUrls.searchProductCoupon + "?s=" + this.state.name)
//   //     .then((resp) => {
//   //       console.log(resp);

//   //       resp.data.forEach((item) => {
//   //         this.state.data.push({
//   //           label: item.name,
//   //           value: item._id,
//   //         });
//   //       });

//   //       this.setState(
//   //         {
//   //           data: this.state.data,
//   //           searching: false
//   //         },

//   //       );
//   //     });
//   // };
//   render() {
//     if (this.state.redirect) {
//       return <Redirect to={"/admin/couponcodes"} />;
//     } else {
//       return (
//         <>
//           <div className="content">
//             {this.state.notification ? (
//               <div
//                 style={{
//                   width: "250px",
//                   position: "relative",
//                   left: "40%",
//                   top: "7px",
//                 }}
//               >
//                 <UncontrolledAlert color="success" fade={false}>
//                   <span className="alert-inner--icon">
//                     <i className="ni ni-like-2" />
//                   </span>{" "}
//                   <span className="alert-inner--text">
//                     <strong>Coupon Code Added!</strong>
//                   </span>
//                 </UncontrolledAlert>
//               </div>
//             ) : null}
//             {this.state.warn ? (
//               <div
//                 style={{
//                   width: "250px",
//                   position: "relative",
//                   left: "40%",
//                   top: "7px",
//                 }}
//               >
//                 <UncontrolledAlert color="danger" fade={false}>
//                   <span className="alert-inner--icon">
//                     <i className="ni ni-dislike-2" />
//                   </span>{" "}
//                   <span className="alert-inner--text">
//                     <strong>Some Error Occured!</strong>
//                   </span>
//                 </UncontrolledAlert>
//               </div>
//             ) : null}
//             <Row>
//               <Col md="12">
//                 <Card>
//                   <CardHeader>
//                     <CardTitle tag="h4">Add Coupon Code</CardTitle>
//                   </CardHeader>
//                   <CardBody>
//                     <Form onSubmit={this.handleSubmit}>
//                       <Row>

//                       {/* <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Super Category</label>
//                             <br />
//                             <select
//                               className="form-control"
//                               name="super_category"
//                               required={true}
//                               onChange={this.handleCatgory}
//                             >
//                               <option value="">Select an Option</option>
//                               {this.state.super_categories.map((v, i) => (
//                                 <option key={i} value={v._id}>
//                                   {v.title}
//                                 </option>
//                               ))}
//                             </select>
//                           </FormGroup>
//                         </Col> */}


//                         {/* <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Category</label>
//                             <br />
//                             <select
//                               className="form-control"
//                               name="category"
//                               required={true}
//                               onChange={this.handleSubCategory}
//                             >
//                               <option value="">Select an Option</option>
//                               {this.state.categories.map((v, i) => (
//                                 <option key={i} value={v._id}>
//                                   {v.name}
//                                 </option>
//                               ))}
//                             </select>
//                           </FormGroup>
//                         </Col> */}
                      

//                         {/* <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Sub Category</label>
//                             <br />
//                             <select
//                               className="form-control"
//                               name="sub_category"
//                               required={true}
//                               onChange={this.handleSearch}
//                             >
//                               <option value="">Select Category First!</option>
//                               {this.state.sub_categories.map((v, i) => (
//                                 <option key={i} value={v._id}>
//                                   {v.name}
//                                 </option>
//                               ))}
//                             </select>
//                           </FormGroup>
//                         </Col> */}

//                         {/* <Col className="pr-1" md="4">
                          
//                           <label htmlFor="Products">Products</label>
                          
//                           <select
//                             className="form-control"
//                             name="products"
//                             value={this.state.selectedID}
//                             required={true}
//                             // onChange={this.handleInput}

//                             onChange={(e) => {
//                               this.setState({
//                                 selectedID: e.target.value,
//                               });
//                             }}
//                           >
//                             <option value="" style={{ height: "5px" }}>
//                               Select an Option
//                             </option>
//                             {this.state.all_filter_product.map((v, i) => (
//                               <option key={i} value={v._id}>
//                                 {v.name}
//                               </option>
//                             ))}
//                           </select>
                       
                       
//                         </Col> */}

//                         <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Name</label>
//                             <Input
//                               placeholder="Name"
//                               name="name"
//                               onChange={this.handleInput}
//                               required={true}
//                               type="text"
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Code</label>
//                             <Input
//                               placeholder="Code"
//                               name="code"
//                               onChange={this.handleInput}
//                               required={true}
//                               type="text"
//                             />
//                           </FormGroup>
//                         </Col>

//                         {/* <Col
//                           style={{ marginTop: "4px" }}
//                           className="pr-1"
//                           md="4"
//                         >
//                           <label htmlFor="Courses">Products</label>
//                           <Input
//                             placeholder="Name"
//                             name="name"
//                             style={{ width: "70%", marginBottom: "10px" }}
//                             value={this.state.name}
//                             onChange={(e) => {
//                               this.setState({ name: e.target.value });
//                             }}
//                             type="text"
//                           />

//                           <Button
//                             style={{
//                               position: "absolute",
//                               right: "2px",
//                               top: "32px",
//                             }}
//                             className="btn-round"
//                             color="success"
//                             type="submit"
//                             onClick={this.productSearch}
//                           >
//                             {this.state.searching ? "Working" : "Search"}
//                           </Button>
//                           {this.state.visible ? (
//                             <MultiSelect
//                               options={this.state.data}
//                               value={this.state.productIds}

//                               onChange={(data) => {
//                                 this.setState({ productIds: data });
//                               }}
//                               labelledBy={"Products"}
//                             />
//                           ) : null}

//                           {this.state.warn1 ? (
//                             <div>
//                               <span style={{ color: "red" }}>*required</span>
//                             </div>
//                           ) : null}
//                         </Col> */}
//                         <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Offer Type</label>
//                             <br />
//                             <select
//                               className="form-control"
//                               name="type"
//                               required
//                               onChange={this.handleInput}
//                             >
//                               <option value="">Select an Option</option>
//                               {[
//                                 { label: "Amount", value: "amount" },
//                                 { label: "Percent", value: "percent" },
//                               ].map((v, i) => (
//                                 <option key={i} value={v.value}>
//                                   {v.label}
//                                 </option>
//                               ))}
//                             </select>
//                           </FormGroup>
//                         </Col>
//                         <Col className="pr-1" md="4">
//                           <FormGroup>
//                             <label>Discount</label>
//                             <Input
//                               placeholder="Discount"
//                               name="discount"
//                               onChange={this.handleInput}
//                               required={true}
//                               type="number"
//                             />
//                           </FormGroup>
//                         </Col>
//                         <Col
//                           style={{ marginTop: "32px" }}
//                           className="pr-1"
//                           md="4"
//                         >
//                           <ReactDatetime
//                             required={true}
//                             value={this.state.strDate}
//                             inputProps={{
//                               placeholder: "Start Date",
//                             }}
//                             onChange={(e) => {
//                               this.handleDate(e, "strDate");
//                             }}
//                             timeFormat={false}
//                           />
//                           {this.state.warn2 ? (
//                             <div>
//                               <span style={{ color: "red" }}>*required</span>
//                             </div>
//                           ) : null}
//                           <br />
//                         </Col>
//                         <Col  
//                          style={{ marginTop: "32px" }}
//                           className="pr-1" md="4">
//                           <ReactDatetime
//                             value={this.state.endDate}
//                             inputProps={{
//                               placeholder: "End Date",
//                             }}
//                             onChange={(e) => {
//                               this.handleDate(e, "endDate");
//                             }}
//                             timeFormat={false}
//                           />
//                           {this.state.warn3 ? (
//                             <div>
//                               <span style={{ color: "red" }}>*required</span>
//                             </div>
//                           ) : null}
//                         </Col>
//                       </Row>
//                       <Row>
//                         <Col
//                           style={{ marginTop: "8px" }}
//                           className="pr-1"
//                           md="4"
//                         >
//                           <Button
//                             className="btn-round"
//                             color="success"
//                             type="submit"
//                           >
//                             {this.state.loading
//                               ? "Adding..."
//                               : "Add Coupon Code"}
//                           </Button>
//                           <Link to="/admin/couponcodes">
//                             <Button className="btn-round" color="danger">
//                               Cancel
//                             </Button>
//                           </Link>
//                         </Col>
//                       </Row>
//                     </Form>
//                   </CardBody>
//                 </Card>
//               </Col>
//             </Row>
//           </div>
//         </>
//       );
//     }
//   }
// }

// export default AddCouponCode;




import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
import ReactDatetime from "react-datetime";
class AddCouponCode extends Component {
  state = {
    name: "",
    code: "",
    discount: "",
    strDate: "",
    endDate: "",
    type: "",
    productIds: [],
    products: [],
    warn1: false,
    warn2: false,
    warn3: false,
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    data: [],
    visible: false,

    warning: false,
  
    super_categories: [],
    super_category: "",
    categories: [],
    category: "",
    sub_categories: [],
    sub_category: "",
    search: "",
    all_filter_product: [],
    selectedID: "",
  };
  componentDidMount() {
    // axios
    //   .get(Constants.getUrls.products + "?limit=500&skip=0&page=0")
    //   .then(resp => {
    //     // console.log(resp.data);
    //     resp.data.docs.forEach((v) => {
    //       this.state.products.push({
    //         label: v.name,
    //         value: v._id,
    //       });
    //     });
    //     this.setState({
    //       products: this.state.products,
    //     });
    //   });
 
    // bestseller product=======//


    // axios
    // .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1")
    // .then((resp) => {
    //   // console.log(resp);
    //   this.setState({
    //     super_categories: resp.data.superCat.docs,
    //   });
    // });
  }


  // handleCatgory = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //       category: ""
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.categories +
  //             "?limit=50&page=1&status=active" +
  //             `&super_category=` +
  //             this.state.super_category
  //         )
  //         .then((resp) => {
  //           // console.log(resp);
  //           this.setState({
  //             categories: resp.data.docs,
  //           });
  //         });
  //     }
  //   );
  // };

  // handleSubCategory = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       axios
  //         .get(
  //           Constants.getUrls.subCategories +
  //             "?limit=50&page=1&status=active" +
  //             "&category=" +
  //             this.state.category +
  //             `&super_category=` +
  //             this.state.super_category
  //         )
  //         .then((resp) => {
  //           console.log(resp);
  //           this.setState({
  //             sub_categories: resp.data.docs,
  //           });
  //         });
  //     }
  //   );
  // };
  // handleSearch = (e) => {
  //   this.setState(
  //     {
  //       [e.target.name]: e.target.value,
  //     },
  //     () => {
  //       console.log(this.state.search);
  //       axios
  //         .get(
  //           Constants.getUrls.productSearch +
  //             "?super_category=" +
  //             this.state.super_category +
  //             "&category=" +
  //             this.state.category +
  //             "&sub-category=" +
  //             this.state.sub_category
  //         )
  //         .then((resp) => {
  //           // console.log(resp);
  //           if (resp.data.getAllProduct.docs.length == 0) {
  //             this.setState({
  //               all_filter_product: [
  //                 {
  //                   name: "No Product Found!",
  //                   _id: "0",
  //                 },
  //               ],
  //             });
  //           }
  //           if (resp.data.getAllProduct.docs.length > 0) {
  //             this.setState({
  //               all_filter_product: resp.data.getAllProduct.docs,
  //             });
  //           }
  //         });
  //     }
  //   );
  // };




  handleDate = (e, type) => {
    let year = e._d.getFullYear().toString();
    let month = (e._d.getMonth() + 101).toString().substring(1);
    let day = (e._d.getDate() + 100).toString().substring(1);
    if (type === "strDate") {
      this.setState({
        strDate: year + "-" + month + "-" + day,
      });
    } else {
      this.setState({
        endDate: year + "-" + month + "-" + day,
      });
    }
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
   
    if (this.state.strDate === "") {
      this.setState({ warn2: true });
    } else if (this.state.endDate === "") {
      this.setState({ warn3: true });
    } else {
      this.setState({
        loading: true,
      });
      let prodIds = [];
      console.log(this.state.productIds);
      if (this.state.productIds.length > 0) {
        this.state.productIds.forEach((v) => {
          prodIds.push(v.value);
        });
      }
     
      // if (this.state.selectedID === "") {
      //   this.setState({ warn: true });
      // } else {
      //   this.setState({
      //     loading: true,
      //   });
      // let products = [];
      // products.push(this.state.selectedID);
      let payLoad = {
        coupon_name: this.state.name,
        coupon_code: this.state.code,
        offer_type: this.state.type,
        discount: this.state.discount,
        start_date: this.state.strDate,
        end_date: this.state.endDate,
        // prodIds,
        // super_category: this.state.super_category,
        // category: this.state.category,
        // sub_category: this.state.sub_category,
        // products,
      };
      console.log(payLoad);
      axios.post(Constants.getUrls.coupons, payLoad).then((resp) => {
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
    }
  };
  // productSearch = () => {
  //   this.setState({ searching: true, visible: true });
  //   axios
  //     .post(Constants.postUrls.searchProductCoupon + "?s=" + this.state.name)
  //     .then((resp) => {
  //       console.log(resp);

  //       resp.data.forEach((item) => {
  //         this.state.data.push({
  //           label: item.name,
  //           value: item._id,
  //         });
  //       });

  //       this.setState(
  //         {
  //           data: this.state.data,
  //           searching: false
  //         },

  //       );
  //     });
  // };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/couponcodes"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Coupon Code Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>Coupon With This Code Already Exists !</strong>
                    
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Coupon Code</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>

                      {/* <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleCatgory}
                            >
                              <option value="">Select an Option</option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}


                        {/* <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="category"
                              required={true}
                              onChange={this.handleSubCategory}
                            >
                              <option value="">Select an Option</option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                      

                        {/* <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Sub Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="sub_category"
                              required={true}
                              onChange={this.handleSearch}
                            >
                              <option value="">Select Category First!</option>
                              {this.state.sub_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}

                        {/* <Col className="pr-1" md="4">
                          
                          <label htmlFor="Products">Products</label>
                          
                          <select
                            className="form-control"
                            name="products"
                            value={this.state.selectedID}
                            required={true}
                            // onChange={this.handleInput}

                            onChange={(e) => {
                              this.setState({
                                selectedID: e.target.value,
                              });
                            }}
                          >
                            <option value="" style={{ height: "5px" }}>
                              Select an Option
                            </option>
                            {this.state.all_filter_product.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.name}
                              </option>
                            ))}
                          </select>
                       
                       
                        </Col> */}

                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Code</label>
                            <Input
                              placeholder="Code"
                              name="code"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>

                        {/* <Col
                          style={{ marginTop: "4px" }}
                          className="pr-1"
                          md="4"
                        >
                          <label htmlFor="Courses">Products</label>
                          <Input
                            placeholder="Name"
                            name="name"
                            style={{ width: "70%", marginBottom: "10px" }}
                            value={this.state.name}
                            onChange={(e) => {
                              this.setState({ name: e.target.value });
                            }}
                            type="text"
                          />

                          <Button
                            style={{
                              position: "absolute",
                              right: "2px",
                              top: "32px",
                            }}
                            className="btn-round"
                            color="success"
                            type="submit"
                            onClick={this.productSearch}
                          >
                            {this.state.searching ? "Working" : "Search"}
                          </Button>
                          {this.state.visible ? (
                            <MultiSelect
                              options={this.state.data}
                              value={this.state.productIds}

                              onChange={(data) => {
                                this.setState({ productIds: data });
                              }}
                              labelledBy={"Products"}
                            />
                          ) : null}

                          {this.state.warn1 ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col> */}
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Offer Type</label>
                            <br />
                            <select
                              className="form-control"
                              name="type"
                              required
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {[
                                { label: "Amount", value: "amount" },
                                { label: "Percent", value: "percent" },
                              ].map((v, i) => (
                                <option key={i} value={v.value}>
                                  {v.label}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>Discount</label>
                            <Input
                              placeholder="Discount"
                              name="discount"
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col
                          style={{ marginTop: "32px" }}
                          className="pr-1"
                          md="4"
                        >
                          <ReactDatetime
                            required={true}
                            value={this.state.strDate}
                            inputProps={{
                              placeholder: "Start Date",
                            }}
                            onChange={(e) => {
                              this.handleDate(e, "strDate");
                            }}
                            timeFormat={false}
                          />
                          {this.state.warn2 ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                          <br />
                        </Col>
                        <Col  
                         style={{ marginTop: "32px" }}
                          className="pr-1" md="4">
                          <ReactDatetime
                            value={this.state.endDate}
                            inputProps={{
                              placeholder: "End Date",
                            }}
                            onChange={(e) => {
                              this.handleDate(e, "endDate");
                            }}
                            timeFormat={false}
                          />
                          {this.state.warn3 ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{ marginTop: "8px" }}
                          className="pr-1"
                          md="4"
                        >
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Adding..."
                              : "Add Coupon Code"}
                          </Button>
                          <Link to="/admin/couponcodes">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddCouponCode;
