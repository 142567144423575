import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
class AddTaxType extends Component {
  state = {
    name: "",
    value: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
  };
  componentDidMount() {
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
      });
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      tax_type: this.state.name,
      value: this.state.value,
    };
    axios.post(Constants.getUrls.taxTypes, payLoad).then((resp) => {
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/taxtypes"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Tax Type Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Tax Type</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        {/* <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col> */}
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Tax Type</label>
                            <Input
                              placeholder="Tax Type"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Value</label>
                            <Input
                              placeholder="Value"
                              name="value"
                              onChange={this.handleInput}
                              required={true}
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Tax Type"}
                          </Button>
                          <Link to="/admin/taxtypes">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddTaxType;
