import React, { Component } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    FormGroup,
    Form,
    Input,
    UncontrolledAlert
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";

export class UpdateReasons extends Component {
    state = {
        title:"",
        super_categories: [],
        super_category: "Nehal",
        categories: [],
        category: "",
        // title: "",
        
        description: "",
        id: "",
        redirect: false,
        warn: false,
        notification: false,
        loading: false,
    };

    componentDidMount() {
        // this.handleSuperCategory()

            axios
                .get(Constants.getUrls.super_categories 
                    + "?limit=100" + "&skip=0&page=1" + "&is_delete=no")
                .then((resp) => {
                    console.log("super" ,resp);
                    this.setState({
                        super_categories: resp.data.superCat.docs,
                    });
                });
        
        let data = this.props.location.state.data;
        console.log(data)
        this.setState({
            super_category: data.super_category ? data.super_category._id : "",
            category: data.category ? data.category._id : "",
            description: data.description ? data.description : "",
            title: data.title ? data.title:"",
            id: data._id,
        },
        () => {
          axios
            .get(
              Constants.getUrls.categories +
                "?limit=50&page=1&status=active" +
                `&super_category=` +
                this.state.super_category + "&is_delete=no"
            )
            .then((resp) => {
            //   console.log(resp);
              this.setState({
                categories: resp.data.docs,
              });
            });
        });
    }

    // handleSuperCategory = (e) => {
    //     axios
    //         .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1" + "&is_delete=no")
    //         .then((resp) => {
    //             // console.log("super" ,resp);
    //             this.setState({
    //                 super_categories: resp.data.superCat.docs,
    //             });
    //         });
    // }

    handleCategory = (e) => {
        // console.log(e.target.value)
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                axios
                    .get(
                        Constants.getUrls.categories +
                        "?limit=50&page=1&status=active" +
                        `&super_category=` +
                        this.state.super_category + "&is_delete=no"
                    )
                    .then((resp) => {
                        // console.log("resp", resp.data);
                        this.setState({
                            categories: resp.data.docs,
                        });
                    });
            }
        );
    };

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loading: true,
        });
        e.preventDefault();
        let payLoad = {
            title: this.state.title,
            super_category: this.state.super_category,
            category: this.state.category,
            description: this.state.description
        };
        // console.log(payLoad)
        // console.log(this.state.id)
        // console.log(window.localStorage.accessToken)
        // {
        //     headers: { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDEyZDU4MjRmOTA3MjgxMGZlZDQzMjYiLCJpYXQiOjE2Nzk3MzkxNTl9.YLGzmM3EeCa9q-sxNzsSZhrhJ8TPpvjt4h0Tpu8TUtg" },
        // }
        axios
            .put(Constants.getUrls.reasons + "/" + this.state.id, payLoad,
           )
            .then((resp) => {
                if (resp.data.status === "success") {
                    setTimeout(() => {
                        this.setState({
                            redirect: true,
                        });
                    }, 1000);
                    this.setState({
                        notification: true,
                        loading: false,
                    });
                } else {
                    this.setState({
                        warn: true,
                        loading: false,
                    });
                    setTimeout(() => {
                        this.setState({
                            warn: false,
                        });
                    }, 1000);
                }
            });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/admin/reasons"} />;
        } else {
            return (
                <>
                    <div className="content">
                        {this.state.notification ? (
                            <div
                                style={{
                                    width: "250px",
                                    position: "relative",
                                    left: "40%",
                                    top: "7px",
                                }}
                            >
                                <UncontrolledAlert color="success" fade={false}>
                                    <span className="alert-inner--icon">
                                        <i className="ni ni-like-2" />
                                    </span>{" "}
                                    <span className="alert-inner--text">
                                        <strong>Category Updated!</strong>
                                    </span>
                                </UncontrolledAlert>
                            </div>
                        ) : null}
                        {this.state.warn ? (
                            <div
                                style={{
                                    width: "250px",
                                    position: "relative",
                                    left: "40%",
                                    top: "7px",
                                }}
                            >
                                <UncontrolledAlert color="danger" fade={false}>
                                    <span className="alert-inner--icon">
                                        <i className="ni ni-dislike-2" />
                                    </span>{" "}
                                    <span className="alert-inner--text">
                                        {/* <strong>Some Error Occured!</strong> */}
                                        <strong>Reason With This Name Already Exists!</strong>
                                    </span>
                                </UncontrolledAlert>
                            </div>
                        ) : null}
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardHeader>
                                        <CardTitle tag="h4">Update Reasons</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <FormGroup>
                                                        <label>Super Category</label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            name="super_category"
                                                            required={true}
                                                            onChange={this.handleCategory}
                                                            value={this.state.super_category}
                                                        >
                                                            <option value="">Select an Option</option>
                                                            {this.state.super_categories.map((v, i) => (
                                                                <option key={i} value={v._id}>
                                                                    {v.title}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="pr-1" md="6">
                                                    <FormGroup>
                                                        <label>Category</label>
                                                        <br />
                                                        <select
                                                            className="form-control"
                                                            value={this.state.category}
                                                            name="category"
                                                            required={true}
                                                            onChange={this.handleInput}
                                                        >
                                                            <option value="">Select an Option</option>
                                                            {this.state.categories.map((v, i) => (
                                                                <option key={i} value={v._id}>
                                                                    {v.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>

                                                <Col className="pr-1" md="6">
                                                    <FormGroup>
                                                        <label>Title</label>
                                                        <Input
                                                            placeholder="Title"
                                                            value={this.state.title}
                                                            name="title"
                                                            onChange={this.handleInput}
                                                            // required={true}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                 {/* <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Title</label>
                            <Input
                              placeholder="Title"
                              value={this.state.title}
                              name="title"
                              onChange={this.handleInput}
                              type="text"
                            />
                          </FormGroup>
                        </Col> */}

                                                <Col className="pr-1" md="6">
                                                    <FormGroup>
                                                        <label>Description</label>
                                                        <Input
                                                             placeholder="Description"
                                                             value={this.state.description}
                                                            name="description"
                                                            onChange={this.handleInput}
                                                            // required={true}
                                                            type="text"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="pr-1" md="6">
                                                    <Button
                                                        className="btn-round"
                                                        color="success"
                                                        type="submit"
                                                    >
                                                        {this.state.loading ? "Updating..." : "Update Reasons"}
                                                    </Button>
                                                    <Link to="/admin/reasons">
                                                        <Button className="btn-round" color="danger">
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            )
        }
    }
}

export default UpdateReasons




