import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
import MultiSelect from "react-multi-select-component";
class AddHomePage extends Component {
  state = {
    productIds: [],
    products: [],
    filteredProducts: [],
    categories: [],
    category: "",
    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
  };
  componentDidMount() {
    axios
      .get(Constants.getUrls.categories + "?limit=10&skip=0&page=0")
      .then((resp) => {
        console.log(resp.data);
        this.setState({
          categories: resp.data.docs,
        });
      });
      axios.get(Constants.getUrls.products).then(resp =>{
        this.setState({
          filteredProducts: resp.data.data.docs
        })
      })
  }
  handleInput = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      }
      // ,
      // () => {
      //   let category = this.state.categories.find(
      //     (v) => v._id === this.state.category
      //   );
      //   if (category) {
      //     let filteredProducts = [];
      //     category?.products.forEach((v) => {
      //       filteredProducts.push({
      //         label: v.name,
      //         value: v._id,
      //       });
      //     });
      //   }
      //   this.setState({ filteredProducts });
      // }
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.productIds.length === 0) {
      this.setState({ warn: true });
    } else {
      this.setState({
        loading: true,
      });
      let products = [];
      this.state.productIds.forEach((v) => {
        products.push(v.value);
      });
      let payLoad = {
        catId: this.state.category,
        products,
      };
      axios.post(Constants.getUrls.homePages, payLoad).then((resp) => {
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/homepages"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Home Image Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Home Image</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="category"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option value="">Select an Option</option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <label htmlFor="Products">Products</label>
                          <MultiSelect
                            options={this.state.filteredProducts}
                            value={this.state.productIds}
                            onChange={(data) => {
                              this.setState({ productIds: data }, () => {
                                if (this.state.productIds.length === 0) {
                                  this.setState({ warn: true });
                                } else {
                                  this.setState({ warn: false });
                                }
                              });
                            }}
                            labelledBy={"Products"}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Adding..."
                              : "Add Home Image"}
                          </Button>
                          <Link to="/admin/homepages">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddHomePage;
