import axios from "axios";
import React, { Component } from "react";
import Constants from "variables/Constants";
import { SketchPicker } from "react-color";

import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  CardHeader,
  UncontrolledAlert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEye,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default class UpdateVariant extends Component {
  state = {
    image_array: [],
    var_id: "",
    size: "",
    color: "",
    // pack_of: "",
    // design_image: "",
    // price: "",
    mrp_price: "",
    selling_price: "",
    variant_quantity: "",
    // p_id: this.props.location.state.data,
    p_id: this.props.match.params.id,

    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    // data: {},
    data: [],
    allColor: [],

    video: [],
    videoAdded: false,

    //
    imageFile: "",
    photoUrl: "",
    imagePreview: false,
    dataImage: [],
    pop_up: false,
    imagePreviews: [],
    image: [],
    imageupload: [],
    variant_id: "",
    variant_data: {},
    prev_video: "",
  };
  componentDidMount() {
    this.getAllSize();
    this.getAllColor();
    // let data = this.props.location.state.data;
    // console.log(data.image, "data");

    this.setState({ variant_data: this.props.location.state?.data }, () => {
      this.get_variant();
    });
    // return data;
  }

  get_variant = () => {
    this.setState({ loading: true });
    axios
      .get(Constants.getUrls.singleVariant + "/" + this.state.variant_data._id)
      .then((response) => {
        console.log(response, "response");
        const data = response.data.variant;
        this.setState({
          variant_id: this.state.variant_data._id,
          dataImage: data,
          // data: this.props.location.state.data,
          var_id: data._id,
          // id: data._id,
          size: data.size?.size ? data.size._id : "",
          color: data.color?.color ? data.color._id : "",
          // pack_of: data.pack_of,
          // price: data.price,
          mrp_price: data.mrp_price,
          selling_price: data.selling_price,
          variant_quantity: data.variant_quantity,
          image: data.image || [],
          // design_image: data.design_image,
          p_id: data.product._id,
          prev_video: data.video_file,
          loading: false,
        });
      });
  };
  getAllSize = () => {
    axios
      .get(
        Constants.getUrls.getSize +
          "?limit=50" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no" +
          "&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp, "romen");
        this.setState({
          data: resp.data._sizes.docs,
        });
      });
  };

  getAllColor = () => {
    axios
      .get(
        Constants.getUrls.getColor +
          "?limit=50" +
          this.state.perPage +
          "&skip=0&page=" +
          this.state.active +
          "&is_delete=no" +
          "&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp, "romen");
        this.setState({
          allColor: resp.data._colors.docs,
        });
      });
  };

  delete_image = () => {
    axios
      .delete(
        `${Constants.deleteUrl.variant}/${this.state.var_id}/${this.state.image_index}`
      )
      .then((response) => {
        console.log(response, "response");
        if (response.data.status === "success") {
          this.get_variant();
          this.setState({ pop_up: false });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
  };
  update_image = (e, index) => {
    // e.preventDefault();
    let fd = new FormData();
    fd.append("image", e.target.files[0]);

    axios
      .put(
        Constants.putUrls.variant + "/" + this.state.var_id + "/" + index,
        fd
      )
      .then((response) => {
        if (response.data.status === "success") {
          this.get_variant();
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
  };
  handleVideo = (e) => {
    this.setState({ [e.target.name]: e.target.files[0], videoAdded: true });
  };

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let fd = new FormData();
    fd.append("size", this.state.size);
    fd.append("color", this.state.color);
    // fd.append("pack_of", this.state.pack_of);
    console.log(this.state.imageupload);
    if (this.state.imageupload?.length > 0) {
      for (let i = 0; i < this.state.imageupload.length; i++) {
        fd.append("image", this.state.imageupload[i]);
      }
    }
    if (this.state.videoAdded) {
      fd.append("video", this.state.video);
    }
    // for (let i = 0; i < this.state.image.length; i++) {
    //   fd.append("design_image", this.state.design_image[i]);
    // }
    // fd.append("price", this.state.price);
    fd.append("mrp_price", this.state.mrp_price);
    fd.append("selling_price", this.state.selling_price);
    fd.append("variant_quantity", this.state.variant_quantity);
    fd.append("p_id", this.state.p_id);

    axios
      .put(Constants.postUrls.postVariants + "/" + this.state.variant_id, fd)
      .then((resp) => {
        console.log(resp, "hello");
        // fd.delete("image");
        if (resp.data.status === "success") {
          this.get_variant();
          window.location.reload();
          // setTimeout(() => {
          //   this.setState({
          //     redirect: true,
          //   });
          // }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
  };
  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFile = (e) => {
    //  const files = e.target.files;
    //  const urls = [];

    //  for (let i = 0; i < files.length; i++) {
    //    const file = files[i];
    //    const reader = new FileReader();

    //    reader.onloadend = () => {
    //      urls.push(reader.result);
    //      if (urls.length === files.length) {
    //        this.setState((prevState) => ({
    //          imageUrls: [...prevState.imageUrls, ...urls],
    //        }));
    //      }
    //    };

    //    if (file) {
    //      reader.readAsDataURL(file);
    //    }
    //  }
    if (this.state.image.length === 0) {
      this.setState({ [e.target.name]: e.target.files }, () => {
        // console.log(e.target.files);
      });
      return;
    }
    console.log(this.state.image.length, e.target.files.length);
    if (this.state.image.length + e.target.files.length > 8) {
      alert("total files should be less than 8");
      return;
    }
    let fd = new FormData();

    console.log(e.target.files.length);
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        console.log(e.target.files[i]);
        fd.append("images", e.target.files[i]);
      }
      axios
        .put(Constants.putUrls.addVariantImage + this.state.var_id, fd)
        .then((resp) => {
          fd.delete("image");
          if (resp.data.status === "success") {
            setTimeout(() => {
              this.get_variant();
              // this.setState({
              //   redirect: true,
              // });
            }, 1000);
            this.setState({
              notification: true,
              loading: false,
            });
          } else {
            this.setState({
              warning: true,
              loading: false,
            });
            setTimeout(() => {
              this.setState({
                warning: false,
              });
            }, 1000);
          }
        });
      // this.get_variant();
    }
  };
  handlePreview = (e) => {
    const files = e.target.files;
    const reader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      console.log(file, "file");
    }
    console.log(reader, "reader");
  };

  update_preview = () => {};

  // transition_image_overlay = () => {
  //   const element = document.querySelector(".variant-img-delete-icon");
  //   element.

  // }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/variants/" + this.state.p_id} />;
    } else {
      return (
        <div className="content">
          {this.state.notification ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="success" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-like-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>Variant updated !</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          {this.state.warn ? (
            <div
              style={{
                width: "250px",
                position: "relative",
                left: "40%",
                top: "7px",
              }}
            >
              <UncontrolledAlert color="danger" fade={false}>
                <span className="alert-inner--icon">
                  <i className="ni ni-dislike-2" />
                </span>{" "}
                <span className="alert-inner--text">
                  <strong>this Variant Already Added!</strong>
                </span>
              </UncontrolledAlert>
            </div>
          ) : null}

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Update Variants</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmit}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Size </label>
                          <br />
                          {/* <Input
                      placeholder="Size "
                      name="size"
                      onChange={this.handleInput}
                      type="text"
                      value={this.state.size}
                    /> */}
                          <select
                            className="form-control"
                            name="size"
                            required={true}
                            onChange={this.handleInput}
                            value={this.state.size}
                            // defaultValue={this.state.size.size}
                          >
                            <option value="">Select an Option</option>
                            {this.state.data.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.size}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      {/* <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Pack Of</label>
                    <br />
                    <Input
                      placeholder="Pack Of"
                      name="pack_of"
                      onChange={this.handleInput}
                      value={this.state.pack_of}
                    />
                  </FormGroup>
                </Col> */}
                      {/* <Col className="pr-1" md="6">
                  <FormGroup>
                    <label>Price</label>
                    <br />
                    <Input
                      placeholder="Price"
                      name="price"
                      onChange={this.handleInput}
                      value={this.state.price}
                    />
                  </FormGroup>
                </Col> */}
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>MRP Price</label>
                          <br />
                          <Input
                            placeholder="MRP Price"
                            name="mrp_price"
                            onChange={this.handleInput}
                            value={this.state.mrp_price}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Selling Price</label>
                          <br />
                          <Input
                            placeholder="Selling Price"
                            name="selling_price"
                            onChange={this.handleInput}
                            value={this.state.selling_price}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Variant Quantity</label>
                          <br />
                          <Input
                            placeholder="Variant Quantity"
                            name="variant_quantity"
                            onChange={this.handleInput}
                            value={this.state.variant_quantity}
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Color</label>
                          <br />

                          <select
                            className="form-control"
                            name="color"
                            required={true}
                            onChange={this.handleInput}
                            value={this.state.color}
                          >
                            <option value="">Select an Option</option>
                            {this.state.allColor.map((v, i) => (
                              <option key={i} value={v._id}>
                                {v.color}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      <Col style={{ marginTop: "8px" }} md="3">
                        <label>Video</label>
                        <br />
                        <input
                          type="file"
                          onChange={this.handleVideo}
                          name="video"
                          accept="video/*"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: "8px" }}>
                        {this.state.image && (
                          <>
                            <label
                              className="mb-2"
                              style={{ fontSize: "small" }}
                            >
                              Images:
                            </label>
                            {!this.state.loading && (
                              <div className="row d-flex align-items-center position-relative">
                                <br />
                                {this.state.image?.map((ele, index) => (
                                  <div>
                                    <div
                                      className="mr-2 mb-3 border d-flex align-items-center justify-content-center"
                                      style={{
                                        width: 100,
                                        height: 100,
                                        overflow: "hidden",
                                      }}
                                    >
                                      {/* <picture>
                                      <source
                                        // width={100}
                                        height={100}
                                        srcset={Constants.imgUrl + ele}
                                        type="image/webp"
                                      />
                                      <source
                                        width={100}
                                        height={100}
                                        srcset={Constants.imgUrl + ele}
                                        type="image/avif"
                                      />
                                      <source
                                        width={100}
                                        height={100}
                                        srcset={Constants.imgUrl + ele}
                                        type="image/png"
                                      />
                                      <img
                                        src={Constants.imgUrl + ele}
                                        alt={"image"}
                                        width={100}
                                        height={100}
                                      ></img>
                                    </picture> */}
                                      <img
                                        className="m-2 p-2"
                                        width={100}
                                        src={Constants.imgUrl + ele}
                                        alt={ele}
                                      />
                                    </div>
                                    <div className=" d-flex justify-content-center align-items-center">
                                      <div
                                        className="mr-2 position-relative"
                                        style={{
                                          backgroundColor: "#80808030",
                                          height: "30px",
                                          width: "30px",
                                          borderRadius: "50%",
                                          padding: "10px",
                                          position: "relative",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                            fontSize: "small",
                                          }}
                                          onClick={() => {
                                            console.log(
                                              this.state.var_id,
                                              "dataImage"
                                            );
                                            this.setState({
                                              pop_up: true,
                                              image_index: index,
                                            });
                                          }}
                                          icon={faTrash}
                                        />
                                        <input
                                          accept="image/*"
                                          onChange={(e) => {
                                            this.update_image(e, index);
                                          }}
                                          style={{
                                            position: "absolute",
                                            top: "0%",
                                            width: "26px",
                                            left: "40px",
                                            borderRadius: "50%",
                                            opacity: 0,
                                            zIndex: 10,
                                            cursor: "pointer",
                                          }}
                                          type="file"
                                          multiple
                                        />
                                      </div>
                                      <div
                                        style={{
                                          backgroundColor: "#80808030",
                                          height: "30px",
                                          width: "30px",
                                          borderRadius: "50%",
                                          padding: "10px",
                                          position: "relative",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            color: "green",
                                            cursor: "pointer",
                                            fontSize: "small",
                                          }}
                                          icon={faPen}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div
                                  onClick={() => {}}
                                  className="position-relative d-flex align-items-center justify-content-center p-2"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    backgroundColor: "#80808038",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="p-2"
                                    style={{ color: "white" }}
                                    icon={faAdd}
                                  />
                                  <input
                                    accept="image/*"
                                    style={{
                                      position: "absolute",
                                      top: "37%",
                                      right: "37%",
                                      opacity: "0",
                                      width: "30px",
                                      borderRadius: "50%",
                                    }}
                                    type="file"
                                    multiple
                                    onChange={this.handleFile}
                                    required={!this.state.image.length > 0}
                                    name="imageupload"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Col>
                      <Col md={"12"}>
                        {this.state.prev_video && (
                          <>
                            <div>
                              <label
                                className="mb-2"
                                style={{ fontSize: "small" }}
                              >
                                Uploaded Video:
                              </label>
                            </div>
                            <video width="320" height="240" controls>
                              <source
                                src={Constants.imgUrl + this.state.prev_video}
                                type="video/mp4"
                              />
                            </video>
                          </>
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col className="pr-1" md="6">
                        <Button
                          className="btn-round"
                          color="success"
                          type="submit"
                        >
                          {this.state.loading
                            ? "Updating..."
                            : "  Update Variant"}
                        </Button>
                        <Link to="/admin/products">
                          <Button className="btn-round" color="danger">
                            Cancel
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                  {/* // delete_pop_up */}
                  <div
                    className="p-4"
                    style={{
                      display: this.state.pop_up ? "flex" : "none",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      boxShadow: "1px 1px 10px 10px #00000024",
                      backgroundColor: "white",
                      zIndex: 9,
                    }}
                  >
                    <span className="p-2 mb-2">
                      Are You Sure, You Want to Delete this Image?
                    </span>
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: "50%" }}
                    >
                      <button
                        onClick={() => {
                          this.delete_image();
                        }}
                        className="btn btn-danger"
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => {
                          this.setState({ pop_up: false });
                        }}
                        className="btn btn-success"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
