import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
class UpdateSubCategory extends Component {
  state = {
    name: "",
    super_categories: [],
    super_category: "",
    categories: [],
    category: "",
    id: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
  };
  // componentDidMount() {
  //   axios
  //     .get(
  //       Constants.getUrls.categories +
  //         "?limit=20&skip=0&page=0" +
  //         `&super_category=` +
  //         this.state.super_category + "&is_delete=no"
  //     )
  //     .then((resp) => {
  //       //   console.log(resp.data);
  //       this.setState({
  //         categories: resp.data.docs,
  //       });
  //     });
  //   // API of Super Category
  //   axios
  //     .get(Constants.getUrls.super_categories + "?limit=100" + "&skip=0&page=1" + "&is_delete=no")
  //     .then((resp) => {
  //       // console.log(resp);
  //       this.setState({
  //         super_categories: resp.data.superCat.docs,
  //       });
  //     });
  //   let data = this.props.location.state.data;
  //   this.setState({
  //     super_category: data.super_category ? data.super_category._id : "",
  //     name: data.name,
  //     category: data.category._id,
  //     id: data._id,
  //   });
  // }
  componentDidMount() {
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
        // console.log(resp.data);(this.state.suggestions);
        let data = this.props.location.state.data;
        this.setState(
          {
            super_category: data.super_category ? data.super_category._id : "",
            category: data.category ? data.category._id : "",
            description: data.description ? data.description : "",
            name: data.name,
            id: data._id,
          },
          () => {
            axios
              .get(
                Constants.getUrls.categories +
                  "?limit=50&page=1&status=ACTIVE" +
                  `&super_category=` +
                  this.state.super_category +
                  "&is_delete=no"
              )
              .then((resp) => {
                // console.log(resp);
                this.setState({
                  categories: resp.data.docs,
                });
              });
          }
        );
      });
  }

  handleCatgory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=ACTIVE" +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            // console.log(resp.data);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    let payLoad = {
      name: this.state.name,
      catId: this.state.category,
      superCatId: this.state.super_category,
    };
    axios
      .put(Constants.getUrls.subCategories + "/" + this.state.id, payLoad)
      .then((resp) => {
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warn: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warn: false,
            });
          }, 1000);
        }
      });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/subcategories"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Sub Category Updated!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong>This SubCategory Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Update Sub Category</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleCatgory}
                              value={this.state.super_category}
                            >
                              <option disabled selected="true" value="">
                                Select an Option
                              </option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="category"
                              required={true}
                              value={this.state.category}
                              onChange={this.handleInput}
                            >
                              <option disabled selected="true" value="">
                                Select an Option
                              </option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Subcategory Name</label>
                            <Input
                              placeholder="Name"
                              value={this.state.name}
                              name="name"
                              onChange={this.handleInput}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Updating..."
                              : "Update Sub Category"}
                          </Button>
                          <Link to="/admin/subcategories">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UpdateSubCategory;
