import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import ReactTags from "react-tag-autocomplete";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Redirect, Link } from "react-router-dom";
class AddProductFor extends Component {
  state = {
    description: "",
    image: [],
    categories: [],
    tags: [],
    suggestions: [],
    category: "",
    tagsByIdInString: "",
    tagsAlert: false,
    warn: false,
    redirect: false,
    warning: false,
    notification: false,
    loading: false,
    super_categories: [],
    super_category: "",
    // image preview
    imageFile: "",
    photoUrl: "",
  };
  componentDidMount() {
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          super_categories: resp.data.superCat.docs,
        });
      });
  }

  handleCatgory = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        axios
          .get(
            Constants.getUrls.categories +
              "?limit=50&page=1&status=ACTIVE" +
              `&super_category=` +
              this.state.super_category +
              "&is_delete=no"
          )
          .then((resp) => {
            console.log(resp);
            this.setState({
              categories: resp.data.docs,
            });
          });
      }
    );
  };
  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };
  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDelete(i) {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags }, () => {
      if (this.state.tags.length === 0) {
        this.setState({ warn: true });
      } else {
        this.setState({ warn: false });
      }
    });
  }

  onAddition(tag) {
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags }, () => {
      if (this.state.tags.length === 0) {
        this.setState({ warn: true });
      } else {
        this.setState({ warn: false });
      }
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.tags.length === 0) {
      this.setState({ warn: true });
    } else {
      this.setState({
        loading: true,
      });
      let fd = new FormData();
      let tags = [];
      this.state.tags.forEach((v) => {
        tags.push(v.name);
      });

      fd.append("catId", this.state.category);
      fd.append("image", this.state.image);
      if (this.state.description) {
        fd.append("description", this.state.description);
      }
      fd.append("category_name", JSON.stringify(tags));
      fd.append("super_category", this.state.super_category);
      axios.post(Constants.getUrls.productsFor, fd).then((resp) => {
        if (resp.data.status === "success") {
          setTimeout(() => {
            this.setState({
              redirect: true,
            });
          }, 1000);
          this.setState({
            notification: true,
            loading: false,
          });
        } else {
          this.setState({
            warning: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({
              warning: false,
            });
          }, 1000);
        }
      });
    }
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/productsfor"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Product For Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warning ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Some Error Occured!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}

            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Tribes</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleCatgory}
                            >
                              <option disabled selected="true" value="">
                                Select an Option
                              </option>
                              {this.state.super_categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="3">
                          <FormGroup>
                            <label>Category</label>
                            <br />
                            <select
                              className="form-control"
                              value={this.state.category}
                              name="category"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option disabled selected="true" value="">
                                Choose Super Category First!
                              </option>
                              {this.state.categories.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="12">
                          <label>Product For Title</label>
                          <br />
                          <ReactTags
                            style={{
                              width: "120%",
                            }}
                            placeholder="Enter Product For"
                            tags={this.state.tags}
                            allowNew={true}
                            suggestions={this.state.suggestions}
                            handleDelete={this.onDelete.bind(this)}
                            handleAddition={this.onAddition.bind(this)}
                          />
                          {this.state.warn ? (
                            <div>
                              <span style={{ color: "red" }}>*required</span>
                            </div>
                          ) : null}
                        </Col>
                        <Col md="12">
                          <label>Description</label>
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              //console.log({ data });
                              this.setState({
                                description: data,
                              });
                            }}
                          />
                          <br />
                        </Col>
                        <Col style={{ marginTop: "8px" }} md="6">
                          <Row>
                            <Col style={{ marginTop: "8px" }} md="6">
                              <label>Image</label>
                              <br />
                              <input
                                type="file"
                                onChange={(e) => {
                                  this.handleFile(e);
                                  this.handlePreview(e);
                                }}
                                accept="image/*"
                                required={true}
                                name="image"
                              />
                            </Col>
                            <Col style={{ marginTop: "8px" }} md="6">
                              <label>Image Preview</label>
                              <br />
                              <img
                                width={100}
                                src={this.state.photoUrl}
                                alt=""
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <br />
                      <Row className="mt-5">
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading
                              ? "Adding..."
                              : "Add Product For"}
                          </Button>
                          <Link to="/admin/productsfor">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddProductFor;
