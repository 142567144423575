import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import React, { useEffect, useState } from "react";
import "assets/scss/argon-dashboard/custom/_addSettings.scss";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "variables/Constants";
// React-Time-Picker
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Modal from "react-awesome-modal";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";

function AddSettings() {
  const [payload, setPayload] = useState();
  const [file, setFile] = useState({});
  const [settData, setSettData] = useState();

  const [isUpdate, setIsUpdate] = useState(false);
  const [aboutUs, setAboutUs] = useState("");
  // const [isUpdate, setIsUpdate] = useState(false);
  // const [isUpdate, setIsUpdate] = useState(false);

  const [updateMsg, setUpdateMsg] = useState("");
  const [visible, setVisible] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const [value, onChange] = useState(["10:00", "11:00"]);

  const fetchSettings = () => {
    axios.get(Constants.getUrls.addSettings).then((resp) => {
      console.log(resp.data.data);
      setPayload(resp.data.data);
      setSettData(resp.data.data);
      console.log(settData?.aboutUs);
      onChange(JSON.parse(resp.data.data.service_timing));
      if (payload === null) {
        setIsUpdate(false);
      } else {
        setIsUpdate(true);
      }
    });
  };
  // useEffect(() => {
  //   fetchSettings();
  //   console.log(settData?.aboutUs);
  // }, []);

  useEffect(() => {
    console.log(payload);
  }, [payload]);
  useEffect(() => {
    fetchSettings();
    console.log(settData?.aboutUs);
  }, []);

  const setFileValue = (e) => {
    setFile(e.target.files[0]);
  };

  const input = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   console.log(payload);
  // }, [payload]);

  const addSettings = (e) => {
    // setVisible(true);
    e.preventDefault();
    let fd = new FormData();
    if (file.name) {
      fd.append("logo", file);
    }
    console.log(payload);
    fd.append("title", payload.title);
    if (payload.shipping_return !== null) {
      fd.append("shipping_return", payload.shipping_return);
    } else {
      fd.append("shipping_return", payload.shipping_return);
    }
    if (payload.privacy_policy !== null) {
      fd.append("privacy_policy", payload.privacy_policy);
    } else {
      fd.append("privacy_policy", payload.privacy_policy);
    }
    if (payload.aboutus !== null) {
      fd.append("aboutus", payload.aboutus);
    } else {
      fd.append("aboutus", payload.aboutus);
    }

    fd.append("condition_of_use", payload.condition_of_use);
    fd.append("contact_info", payload.contact_info);
    fd.append("mail_info", payload.mail_info);
    fd.append("link", payload.link);
    fd.append("delivery_free_upto", payload.delivery_free_upto);
    fd.append("service_timing", JSON.stringify(value));

    if (isUpdate) {
      fd.append("id", payload._id);
    }

    axios
      .post(Constants.postUrls.addSettings, fd)
      .then((resp) => {
        console.log(resp);
        if (resp.data.status === "success") {
          setUpdateMsg(resp.data.message);
        } else {
          setUpdateMsg(resp.data.message);
        }
        setPayload(resp.data.data);
      })
      .catch((error) => {
        if (error) {
          setUpdateMsg(error.response.data.message);
        }
      });

    // let notification = document.getElementById("msgNotification");
    // notification.classList.toggle("__updating-message-in");
  };
  // useEffect(() => {
  //   add();
  // }, []);

  return (
    <>
      {/* <Col
        id="msgNotification"
        className="bg-black text-center p-3 __updating-message"
        md="4"
      >
        <h1>{updateMsg}hi</h1>
      </Col> */}
      <Modal
        visible={visible}
        width="600"
        height="100"
        effect="fadeInUp"
        onClickAway={() => {
          setVisible(false);
          setBtnDisable(false);
        }}
      >
        <Row>
          <Col xs={12} sm={12} md={12}>
            <Card>
              {/* <CardHeader color="rose" icon>
                    <CardIcon color="danger">
                      <EditIcon />
                    </CardIcon>
                  <h4>Stacked Form</h4> 
                  </CardHeader> */}
              <CardBody>
                <div>
                  <h3 style={{ textAlign: "center" }}>{updateMsg}</h3>

                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xs={12} sm={12} md={4}>
                      <br />

                      <Button
                        className="w-100"
                        color="success"
                        onClick={() => {
                          setVisible(false);
                          setBtnDisable(false);
                        }}
                      >
                        Ok
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Modal>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h1">Settings</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={addSettings}>
                <Row>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label>Change Logo</label>
                      <br />
                      <Input
                        type="file"
                        name="logo"
                        onChange={setFileValue}
                      ></Input>
                      {/* <button type="submit" name="Upload">
                      Upload
                      </button>*/}
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label>Website Title</label>
                      <br />
                      <Input
                        type="text"
                        name="title"
                        onChange={input}
                        placeholder="title"
                        value={payload?.title}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label>Delivery Free Upto</label>
                      <br />
                      <Input
                        placeholder="Delivery Free Upto "
                        name="delivery_free_upto"
                        required={true}
                        onChange={input}
                        type="text"
                        value={payload?.delivery_free_upto}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label>Service Timing</label>
                      <br />
                      {/* <Input
                        placeholder="Service Timing "
                        name="service_timing"
                        required={true}
                        onChange={input}
                        type="text"
                        value={payload?.service_timing}
                      /> */}

                      <TimeRangePicker
                        onChange={onChange}
                        name="service_timing"
                        // onChange={input}
                        value={value}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label> Condition of Use</label>
                      <br />
                      <Input
                        placeholder="Condition of Use "
                        name="condition_of_use"
                        required={true}
                        onChange={input}
                        type="text"
                        value={payload?.condition_of_use}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label> Contact Info</label>
                      <br />
                      <Input
                        placeholder="Contact Info "
                        name="contact_info"
                        required={true}
                        onChange={input}
                        type="text"
                        value={payload?.contact_info}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label> Mail Info</label>
                      <br />
                      <Input
                        placeholder="Mail Info "
                        name="mail_info"
                        required={true}
                        onChange={input}
                        type="Email"
                        value={payload?.mail_info}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" sm="6" md="3">
                    <FormGroup>
                      <label>Support Link</label>
                      <br />
                      <Input
                        placeholder="Support Link "
                        name="link"
                        required={true}
                        onChange={input}
                        type="Email"
                        value={payload?.link}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label> Custom Message</label>
                      <br />
                      <CKEditor
                        editor={ClassicEditor}
                        // onReady={(editor) => {
                        //   editor.setData(payload?.aboutus);
                        // }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPayload({ ...payload, custom_msg: data });
                        }}
                        data={settData?.custom_msg ? settData?.custom_msg : ""}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label> Shipping Return</label>
                      <br />
                      <CKEditor
                        editor={ClassicEditor}
                        // onReady={(editor) => {
                        //   editor.setData(payload?.aboutus);
                        // }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPayload({ ...payload, shipping_return: data });
                        }}
                        data={
                          settData?.shipping_return
                            ? settData?.shipping_return
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label> Privacy Policy</label>
                      <br />
                      <CKEditor
                        data={
                          settData?.privacy_policy
                            ? settData?.privacy_policy
                            : ""
                        }
                        editor={ClassicEditor}
                        // onReady={(editor) => {
                        //   editor.setData(payload?.aboutus);
                        // }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPayload({ ...payload, privacy_policy: data });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label> About us</label>
                      <br />
                      {console.log(settData?.aboutus)}
                      <CKEditor
                        data={settData?.aboutus ? settData?.aboutus : ""}
                        editor={ClassicEditor}
                        // onReady={(editor) => {
                        //   editor.setData(payload?.aboutus);
                        // }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setPayload({ ...payload, aboutus: data });
                        }}
                        // data={payload?.aboutus || ""}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <button
                      disabled={btnDisable}
                      className="btn-round btn btn-success"
                      onClick={(e) => {
                        addSettings(e);
                        setVisible(true);
                        setBtnDisable(true);
                      }}
                    >
                      {isUpdate ? "Update" : "Add"}
                    </button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AddSettings;
