import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Input,
  UncontrolledAlert,
} from "reactstrap";
import axios from "axios";
import Constants from "../variables/Constants";
import { Redirect, Link } from "react-router-dom";
class AddCategory extends Component {
  state = {
    name: "",
    redirect: false,
    warn: false,
    notification: false,
    loading: false,
    data: [],
    super_category: "",
    // image preview
    imageFile: "",
    photoUrl: "",
  };

  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    axios
      .get(
        Constants.getUrls.super_categories +
          "?limit=100" +
          "&skip=0&page=1" +
          "&is_delete=no&status=ACTIVE"
      )
      .then((resp) => {
        // console.log(resp);
        this.setState({
          data: resp.data.superCat.docs ? resp.data.superCat.docs : [],
        });
      });
  };

  handleFile = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
  };
  handlePreview = (e) => {
    this.setState({ imageFile: e.target.files[0] }, () => {
      if (this.state.imageFile) {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ photoUrl: reader.result });
        };
        reader.readAsDataURL(this.state.imageFile);
      }
    });
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    // let payLoad = {
    //   name: this.state.name,
    //   super_category: this.state.super_category,
    // };

    let fd = new FormData();

    fd.append("name", this.state.name);
    fd.append("image", this.state.file);
    fd.append("super_category", this.state.super_category);

    axios.post(Constants.getUrls.categories, fd).then((resp) => {
      // console.log(resp);
      if (resp.data.status === "success") {
        setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 1000);
        this.setState({
          notification: true,
          loading: false,
        });
      } else {
        this.setState({
          warn: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({
            warn: false,
          });
        }, 1000);
      }
    });
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/admin/categories"} />;
    } else {
      return (
        <>
          <div className="content">
            {this.state.notification ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="success" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-like-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    <strong>Category Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            {this.state.warn ? (
              <div
                style={{
                  width: "250px",
                  position: "relative",
                  left: "40%",
                  top: "7px",
                }}
              >
                <UncontrolledAlert color="danger" fade={false}>
                  <span className="alert-inner--icon">
                    <i className="ni ni-dislike-2" />
                  </span>{" "}
                  <span className="alert-inner--text">
                    {/* <strong>Some Error Occured!</strong> */}
                    <strong> This Category Already Added!</strong>
                  </span>
                </UncontrolledAlert>
              </div>
            ) : null}
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Add Category</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Super Category</label>
                            <br />
                            <select
                              className="form-control"
                              name="super_category"
                              required={true}
                              onChange={this.handleInput}
                            >
                              <option selected="true" disabled value="">
                                Select an Option
                              </option>
                              {this.state.data.map((v, i) => (
                                <option key={i} value={v._id}>
                                  {v.title}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Name</label>
                            <Input
                              placeholder="Name"
                              name="name"
                              onChange={this.handleInput}
                              required={true}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Row>
                            <Col className="pr-1" md="6">
                              <FormGroup>
                                <label>Image</label>
                                <input
                                  placeholder="File"
                                  name="file"
                                  onChange={(e) => {
                                    this.handleFile(e);
                                    this.handlePreview(e);
                                  }}
                                  required={true}
                                  type="file"
                                />
                              </FormGroup>
                            </Col>
                            <Col className="pr-1" md="6">
                              <FormGroup>
                                <label>Image preview</label>
                                <br />
                                <img
                                  width={100}
                                  src={this.state.photoUrl}
                                  alt=""
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="pr-1" md="6">
                          <Button
                            className="btn-round"
                            color="success"
                            type="submit"
                          >
                            {this.state.loading ? "Adding..." : "Add Category"}
                          </Button>
                          <Link to="/admin/categories">
                            <Button className="btn-round" color="danger">
                              Cancel
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AddCategory;
